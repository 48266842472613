import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import axiosInstance from '~/helpers/axios'
import { Spin } from 'antd'

class ExamView extends Component {
    constructor(props){
        super(props)
        this.state = {
            props : props,
            idExame: null,
            motivo: "",
            statusLaudo: "",
            idPaciente: null,
            idade: null,
            sexo: "",
            urlExame:"",
            motivoRejeicao:"",
            isLoading: false,
            isLoadingPdf: false
        }
        this.componentDidMount = this.componentDidMount.bind(this)
        this.onChange = this.onChange.bind(this)
        this.rejeitar = this.rejeitar.bind(this)
        /* this.efetivar = this.efetivar.bind(this) */
    }
    componentDidMount(){
        var $ = window.$
        var dataLoad = this.props.location.state
        console.log(dataLoad)
        const {state} = this
        console.log(state)
        axiosInstance.get("/exame/pdf/"+dataLoad.idExame)
            .then((response)=>{
                console.log(response)
                this.setState({
                    isLoadingPdf:true
                })
                this.setState({
                    urlExame:response.data.urlExame
                })
            }).catch((error)=>{
                console.log(error)
            })
        $(function () {
        })
    }
    onChange(event){
        let { name, value } = event.target;
        this.setState({[name]:value})
    }
    rejeitar(){
        const {state} = this
        var dataLoad = this.props.location.state
        this.setState({
            isLoading:true
        })
        var data = {
            "statusLaudo": "REJEITADO",
            "motivo":this.state.motivoRejeicao
        }
        console.log(data)
        axiosInstance.put('/exame/'+dataLoad.idExame,data)
        .then((response) => {
            window.$('#your-modal-id').modal('hide');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
            this.setState({
                isLoading:false
            })
            state.props.history.push({
                pathname:'/ExamList'
            })
        })
    }
    render() {
        const {state} = this
        var dataLoad = this.props.location.state
        let rejected
        if(dataLoad.statusLaudo=="REJEITADO"){
            rejected = <div><label>Motivo de rejeição</label><input className="form-control" type="textarea" value={dataLoad.motivo} disabled/></div>
        }else if(localStorage.getItem("role")=="doctor" && (dataLoad.statusLaudo=="DISPONIVEL")){
            rejected =  <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#rejeitar"data-backdrop="static">Rejeitar Exame</button>
                            </div>
                        </div>
        }else{
            rejected = null
        }
        return (
            <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Visualizar exame</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                    </div>
                </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Exame</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <h3>Dados do Exame</h3>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group form-row">
                                <div className="col-3">
                                    <label>ID do Laudo</label>
                                    <input className="form-control" type="text" value={dataLoad.idLaudo} disabled/>
                                </div>
                                <div className="col-3">
                                    <label>Status</label>
                                    <input className="form-control" type="text" value={dataLoad.statusLaudo} disabled/>
                                </div>
                            </div>
                            <div className="form-group form-row">
                                <div className="col-2">
                                    <label>Sexo</label>
                                    <input className="form-control" type="text"  value={dataLoad.sexo} disabled/>
                                </div>
                                <div className="col-2">
                                    <label>Idade</label>
                                    <input className="form-control" type="text" maxLength="2" value={dataLoad.idade} disabled/>
                                </div>
                                <div className="col-4">
                                    <label>Data da Coleta</label>
                                    <input className="form-control" type="text" value={dataLoad.dataInicioColetaFormatada} disabled/>
                                </div>
                                <div className="col-4">
                                    <label>Data do Laudo</label>
                                    <input className="form-control" type="text" value={dataLoad.dataLaudoFormatada?dataLoad.dataLaudoFormatada:"Pendente"} disabled/>
                                </div>
                            </div>
                            <div className="form-group form-row">
                                <div className="col-10">
                                    <label>Conclusão</label>
                                    <textarea className="form-control" rows="10" disabled>{dataLoad.conclusao?dataLoad.conclusao:"Pendente"}</textarea>
                                    </div>
                            </div>
                            <div className="form-group form-row">
                                <div className="col-5">
                                    {rejected}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.row */}
                    {state.isLoadingPdf?<embed src={state.urlExame} width="100%" height="1250" type="application/pdf" />:<div style={{margin: "50px auto", display:'flex', justifyContent:'center'}}> <Spin size='large' /></div>}
                </div>
                {/* /.card-body */}
                </div>
                {/* /.card */}
                <div className="modal fade" id="rejeitar">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Rejeitar exame</h4>
                            </div>
                            {this.state.isLoading?
                                <div style={{margin: "50px auto"}}> 
                                    <Spin size='large' />
                                </div>:<>
                            <div className="modal-body">
                                <div className="col-10">
                                    <label>Motivo</label>
                                    <input className="form-control" type="text" name="motivoRejeicao" onChange={this.onChange} value={state.motivoRejeicao}/>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button id='cancel-btn' type="button" className="btn btn-primary" data-dismiss="modal">Fechar</button>
                                <button type="button" className="btn btn-primary" onClick={this.rejeitar}>Rejeitar</button>
                            </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="efetivar">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Efetivar exame</h4>
                            </div>
                            {this.state.isLoading?
                                    <div style={{margin: "50px auto"}}> 
                                        <Spin size='large' />
                                    </div>:<>
                            <div className="modal-body">
                                Deseja realmente efetivar a entrega deste exame?
                            </div>
                            <div className="modal-footer justify-content-between" data-bs-backdrop="false">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Fechar</button>
                                <button type="button" className="btn btn-primary" onClick={this.efetivar}>Efetivar</button>
                            </div></>}
                        </div>
                    </div>
                </div>
            </section>
            {/* /.content */}
            </div>
        )
    }
}
export default withRouter(ExamView);


//Caso precise

/* else if(localStorage.getItem("role")=="doctor" && (dataLoad.statusLaudo=="ASSINADO" || dataLoad.statusLaudo=="LAUDADO")){
            rejected =  <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#efetivar" data-backdrop="static">Efetivar Laudo</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#rejeitar"data-backdrop="static">Rejeitar Exame</button>
                            </div>
                        </div>
        } */

/* efetivar(){
        const {state} = this
        var dataLoad = this.props.location.state
        this.setState({
            isLoading:true
        })
        var data = {
            "statusLaudo": "EFETIVADO"
        }
        console.log(data)
        axiosInstance.put('/exame/'+dataLoad.idExame,data)
        .then((response) => {
            window.$('#your-modal-id').modal('hide');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
            this.setState({
                isLoading:false
            })
            state.props.history.push({
                pathname:'/ExamList'
            })
        })
    } */