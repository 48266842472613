import React from 'react'
import { BrowserRouter as Router, Switch} from 'react-router-dom'

import StoreProvider from 'src/components/Store/Provider'

import DashboardRoutes from '~/layouts/Dashboard/DashboardRoutes'
import LoginRoutes from '~/layouts/Login/LoginRoutes'
import ForgetRoute from '~/layouts/ForgetPass/ForgetRoute'
import TermLocalRoutes from '~/layouts/TermLocal/TermLocalRoutes'

import Home from '~/pages/home/Home'
import TestPage from '~/pages/TestPage'

//ForgetPassword
import EmailSign from "./pages/forget-pass/EmailSign"
import NewPassword from "./pages/forget-pass/NewPassword"

//Password
import ChangePassword from "./pages/password-pages/ChangePassword"


//Dashboards
import MainDashboard from '~/pages/business-intelligence/MainDashboard'
import CentralDashboard from "~/pages/business-intelligence/CentralDashboard"
import ClinicaDashboard from "~/pages/business-intelligence/ClinicaDashboard"


//Clinic
import ClinicListing from '~/pages/clinics/ClinicListing'
import ClinicNewForm from '~/pages/clinics/ClinicNewForm'
import ClinicEditForm from '~/pages/clinics/ClinicEditForm'
//Company
import CompanyListing from '~/pages/companies/CompanyListing'
import CompanyNewForm from '~/pages/companies/CompanyNewForm'
import CompanyEditForm from '~/pages/companies/CompanyEditForm'
//Practitioner
import PractitionerListing from '~/pages/practitioners/PractitionerListing'
import PractitionerNewForm from '~/pages/practitioners/PractitionerNewForm'
import PractitionerEditForm from '~/pages/practitioners/PractitionerEditForm'
import PractitionerProfileEdit from '~/pages/practitioners/PractitionerProfileEdit'
//AuditLog
import Timeline from '~/pages/audit-log/Timeline'
import ViewLog from '~/pages/audit-log/ViewLog'

//Exams
import ExamListing from '~/pages/exam/ExamListing'
import ExamView from '~/pages/exam/ExamView'
import RejectedListing from '~/pages/exam/RejectedListing'
import WarrantedListing from '~/pages/exam/WarrantedListing'
import ExamListingFilter from '~/pages/exam/ExamListingFilter'

//Login
import Login from '~/pages/login/Login'

//Term
import TermPage from '~/pages/TermPage'

import Appointment from '~/pages/Appointment'

export default function App() {
  return (
    <Router>
      <StoreProvider>
        <Switch>
          <LoginRoutes exact path="/Login" component={Login}/>

          <ForgetRoute exact path="/Email" component={EmailSign} />
          <ForgetRoute exact path="/NewPassword" component={NewPassword} />

          <DashboardRoutes exact path="/" component={Home}/>
          <DashboardRoutes exact path="/Testes" component={TestPage} />

          <DashboardRoutes exact path="/Dashboard" component={MainDashboard}/>
          <DashboardRoutes exact path="/DashboardCentral" component={CentralDashboard}/>
          <DashboardRoutes exact path="/ClinicaDashboard" component={ClinicaDashboard}/>

          <DashboardRoutes exact path="/ClinicList" component={ClinicListing} />
          <DashboardRoutes exact path="/ClinicNew" component={ClinicNewForm} />
          <DashboardRoutes exact path="/ClinicEdit" component={ClinicEditForm} />

          <DashboardRoutes exact path="/CompanyList" component={CompanyListing} />
          <DashboardRoutes exact path="/CompanyNew" component={CompanyNewForm} />
          <DashboardRoutes exact path="/CompanyEdit" component={CompanyEditForm} />

          <DashboardRoutes exact path="/DoctorList" component={PractitionerListing} />
          <DashboardRoutes exact path="/DoctorNew" component={PractitionerNewForm} />
          <DashboardRoutes exact path="/DoctorEdit" component={PractitionerEditForm} />
          <DashboardRoutes exact path="/Profile" component={PractitionerProfileEdit} />

          <DashboardRoutes exact path="/AuditLog" component={Timeline} />
          <DashboardRoutes exact path="/ViewLog" component={ViewLog} />

          <DashboardRoutes exact path="/ExamList" component={ExamListing} />
          <DashboardRoutes exact path="/ExamListFilter/:filterValue" component={ExamListingFilter} />
          <DashboardRoutes exact path="/ExamView" component={ExamView} />
          <DashboardRoutes exact path="/Rejeitados" component={RejectedListing} />
          {/* <DashboardRoutes exact path="/Abonado" component={WarrantedListing} /> */}
          <DashboardRoutes exact path="/appointment" component={Appointment} />

          <DashboardRoutes exact path="/ChangePassword" component={ChangePassword} />

          <TermLocalRoutes exact path="/Term" component={TermPage} />
        </Switch>
      </StoreProvider>
    </Router>
  )
}
