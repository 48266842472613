import React, { useState, useContext } from 'react'
import axiosInstance from '~/helpers/axios'


const EmailSign = () => {
    const [codigo, setCodigo] = useState("")
    const [senhaNova, setSenhaNova] = useState("")
    const [senhaConfirm, setSenhaConfirm] = useState("")
    const [emailStatus, setEmailStatus] = useState("")

    const data = {
        email: sessionStorage.getItem("email"),
        codigo: codigo,
        senha_nova: senhaNova
    }
    
    function submit(e){
        e.preventDefault()
        setEmailStatus("loading")
        if(senhaNova != senhaConfirm){
            setEmailStatus("inputs")

        }else if(senhaNova === "" || senhaConfirm === "" || codigo === ""){
           setEmailStatus("fill")
        }else{
            axiosInstance.post("/user/forgot-password/reset", JSON.stringify(data)).then(res => {
                setEmailStatus("")
                setSenhaNova("")
                setSenhaConfirm("")
                setCodigo("")
                sessionStorage.clear();
                window.location.href = "/Login"
                console.log(res)
            }).catch(err => {
                setEmailStatus("")
                console.log(err)
                if(err.response.status === 403){
                    setCodigo("")
                    setEmailStatus("error")
                }else if(err.response.status === 500){
                    setEmailStatus("servererror")
                }else if(err.response.status === 401){
                    setEmailStatus("expire")
                }else if(err.response.status === 404){
                    setEmailStatus("notfounded")
                }
            })
        }
    }

    function LoginFailed(props){
        var status = props.status
        if(status=='error') return <div className="alert alert-danger disabled">Código inválido!</div>
        if(status=='inputs') return <div className="alert alert-warning disabled">Senhas estão diferentes</div>
        if(status=='servererror') return <div className="alert alert-warning disabled">Erro no servidor, tente novamente em breve</div>
        if(status=='expire') return <div className="alert alert-warning disabled">Código expirou e/ou usuario não authorizado a alterar a senha.</div>
        if(status=='notfounded') return <div className="alert alert-warning disabled">Usuário não encontrado.</div>
        if(status=='fill') return <div className="alert alert-warning disabled">Preencha os campos</div>
        return null
    }
    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Mude sua senha</p>
                    <form action="/" method="post">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Código de confirmação" name="email" onChange={e => setCodigo(e.target.value)} value={codigo} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-key" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Senha Nova" name="email" onChange={e => setSenhaNova(e.target.value)} value={senhaNova} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Confirme a Senha" name="email" onChange={e => setSenhaConfirm(e.target.value)} value={senhaConfirm} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <LoginFailed status={emailStatus} />
                            {/* /.col */}
                            {emailStatus === "loading"?
                                <div className="fa-2x d-flex justify-content-center"><i className="fas fa-spinner fa-spin"></i></div>:
                                <div className="row justify-content-md-center">
                                    <div className="col-4">
                                        <button className="btn btn-primary btn-block" onClick={submit}>Enviar</button>
                                    </div>
                                </div>
                            }
                            {/* /.col */}
                    </form>
                </div>
                {/* /.login-card-body */}
            </div>
        </div>
    )
}
export default EmailSign
