import React, { Component } from "react";

export default class MainDashboard extends Component {
  componentDidMount() {
    var $  = window.$
    $(function () {
      var data        = [],
      totalPoints = 100
      var data2        = []


    function labelFormatter(label, series) {
      return '<div style="font-size:13px; text-align:center; padding:2px; color: #fff; font-weight: 600;">'
        + label
        + '<br>'
        + Math.round(series.percent) + '%</div>'
    }
    })
  }
  render() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Business Intelligence</h1>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
              <iframe
                    src="https://metabase.telescribecardio.com/public/dashboard/13f1fbbc-12e6-4b17-aedd-e9c12a829415"
                    frameborder="0"
                    width="100%"
                    style={{'min-height':'1400px'}}
                    allowtransparency
                ></iframe>
              </div>
            </div>
          {/*<div className="row">
              <div className="col-12">
                {/* interactive chart *
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="far fa-chart-bar" />
                      Exames
                    </h3>
                    <div className="card-tools">
                      Tempo Real&nbsp;
                      <div className="btn-group" id="realtime" data-toggle="btn-toggle">
                        <button type="button" className="btn btn-default btn-sm active" data-toggle="on">On</button>
                        <button type="button" className="btn btn-default btn-sm" data-toggle="off">Off</button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div id="interactive" style={{height: 300}} />
                  </div>
                  {/* /.card-body*
                </div>
                {/* /.card 
              </div>
              {/* /.col *
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
    );
  }
}
