import React, { Component } from "react";
import axiosInstance from '~/helpers/axios'
import { Spin } from 'antd'

export default class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      urlA:"",
      urlB:"",
      urlC:"",
      disponivel:"-",
      concluido:"-",
      rejeitado:"-",
      total:"-",
      alert:"",
      loading: false
    }
  }
  
  componentDidMount() {
    var $  = window.$

    var role = localStorage.getItem("role");
    this.setState({
      loading: true
    })
    axiosInstance.get("/exame/count")
    .then((response)=>{
        console.log(response.data)
        var data = response.data
        this.setState({
          disponivel:data.disponivel,
          concluido:data.concluido,
          rejeitado:data.rejeitado,
          total:data.total,
          alert:data.alert,
          loading: false
        })
        console.log(this.state.alert)
    })
    .catch((err)=>{
        console.log(err)
    })
    if(role=="hillrom"){
      this.setState({
        urlA:"https://metabase.telescribecardio.com/public/question/5711c318-c164-423f-a35b-75ef1af04eae",
        urlB:"https://metabase.telescribecardio.com/public/dashboard/9961d973-7c89-41db-bee0-338f2fc9e902"
      })
    }else if(role=="clinic"){
      this.setState({
        urlA:"https://metabase.telescribecardio.com/public/question/a73967ae-9885-4563-a8d1-8dc7f6255ee6?id="+localStorage.getItem("access")+"#hide_parameters=id",
        urlB:"https://metabase.telescribecardio.com/public/question/dbcf5338-6599-4c5b-b285-e17ead5429c1?id="+localStorage.getItem("access")+"#hide_parameters=id"
      })
    }else if(role=="doctor"){
      this.setState({
        urlA:"https://metabase.telescribecardio.com/public/question/fc026674-e1ca-4a12-9ae0-56cb44c5e12e?id="+localStorage.getItem("access")+"#hide_parameters=id",
        urlB:""
      })
    }else if(role=="company"){
      this.setState({
        urlA:"https://metabase.telescribecardio.com/public/question/2ebc7a35-b43c-481a-a50e-32a25cbddfce?id="+localStorage.getItem("access")+"#hide_parameters=id",
        urlB:"https://metabase.telescribecardio.com/public/question/425c6d3c-354a-4080-b245-dd450e14f26d?id="+localStorage.getItem("access")+"#hide_parameters=id",
        urlC:"https://metabase.telescribecardio.com/public/question/e3fe3a4c-6949-4f60-9029-805a6590026f?id="+localStorage.getItem("access")+"#hide_parameters=id"
      })
    }else {

    }
    $(function () {
      var data        = [],
      totalPoints = 100

      function getRandomData() {
        if (data.length > 0) {
          data = data.slice(1)
        }
        while (data.length < totalPoints) {
          var prev = data.length > 0 ? data[data.length - 1] : 50,
              y    = prev + Math.random() * 10 - 5
          if (y < 0) {
            y = 0
          } else if (y > 100) {
            y = 100
          }
          data.push(y)
        }
        var res = []
        for (var i = 0; i < data.length; ++i) {
          res.push([i, data[i]])
        }
        return res
      }
    function labelFormatter(label, series) {
      return '<div style="font-size:13px; text-align:center; padding:2px; color: #fff; font-weight: 600;">'
        + label
        + '<br>'
        + Math.round(series.percent) + '%</div>'
    }
    })
    axiosInstance.get("/user/info")
    .then((response)=>{
        console.log(response)
    })
    .catch((err)=>{
        console.log(err)
    })
  }
  render() {
    const {state} = this
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="w-100" style={{paddingLeft: "7.5px", paddingRight: "7.5px"}}>
                <h1 className="m-0 text-dark">Home</h1>
                <hr className="mx-0 my-2" />
                <p style={{fontWeight:"500"}}>Últimos 7 dias</p>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <div div className="info-box mb-3 filtro-exames-hover">
                  <span className="info-box-icon bg-info elevation-1"><i className="fas fa-copy"></i></span>
                  <a href="/ExamList" className="info-box-content text-dark">
                    <div className="info-box-content">
                      <span className="info-box-text">Total Exames</span>
                      <span className="info-box-number">{state.total}</span>
                    </div>
                  </a>
                  {/* /.info-box-content */}
                </div>
                {/* /.info-box */}
              </div>
              {/* /.col */}
              <div className="col-12 col-sm-6 col-md-3">
                <div div className="info-box mb-3 filtro-exames-hover">
                  <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-list-ol"></i></span>
                  <a href="/ExamList" className="info-box-content text-dark">
                    <div className="info-box-content">
                      <span className="info-box-text">A Laudar</span>
                      <span className="info-box-number">{state.disponivel}</span>
                    </div>
                  </a>
                  {/* /.info-box-content */}
                </div>
                {/* /.info-box */}
              </div>
              {/* /.col */}
              {/* fix for small devices only */}
              <div className="clearfix hidden-md-up" />
              <div className="col-12 col-sm-6 col-md-3">
                <div div className="info-box mb-3 filtro-exames-hover">
                  <span className="info-box-icon bg-success elevation-1"><i className="fas fa-thumbs-up" /></span>
                  <a href="/ExamListFilter/LAUDADO" className="info-box-content text-dark">
                    <div className="info-box-content">
                      <span className="info-box-text">Laudados</span>
                      <span className="info-box-number">{state.concluido}</span>
                    </div>
                  </a>
                  {/* /.info-box-content */}
                </div>
                {/* /.info-box */}
              </div>
              {/* /.col */}
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3 filtro-exames-hover">
                  <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-down" /></span>
                  <a href="/ExamListFilter/REJEITADO" className="info-box-content text-dark">
                    <div className="info-box-content">
                      <span className="info-box-text">Rejeitados</span>
                      <span className="info-box-number">{state.rejeitado}</span>
                    </div>
                  </a>
                  {/* /.info-box-content */}
                </div>
                {/* /.info-box */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                <iframe
                    src={state.urlA}
                    frameborder="0"
                    width="auto"
                    height="380"
                    allowtransparency
                ></iframe>
                  {/*<div className="card-header border-0">
                    <div className="d-flex justify-content-between">
                      <h3 className="card-title">Exames</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <p className="d-flex flex-column">
                        <span className="text-bold text-lg">820</span>
                        <span>Exames ao longo do tempo</span>
                      </p>
                      <p className="ml-auto d-flex flex-column text-right">
                        <span className="text-success">
                          <i className="fas fa-arrow-up" /> 12.5%
                        </span>
                        <span className="text-muted">Desde semana passada</span>
                      </p>
                    </div>
                    <div className="position-relative mb-4">
                      <canvas id="visitors-chart" height={200} />
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                      <span className="mr-2">
                        <i className="fas fa-square text-primary" /> Essa semana
                      </span>
                      <span>
                        <i className="fas fa-square text-gray" /> Semana passada
                      </span>
                    </div>
                  </div>*/}
                </div>
                {/* /.card */}
              </div>
              {/* /.col-md-6 */}
              <div className="col-lg-6">
                <div className="card card-default">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-exclamation-triangle" /> Alertas e informativos
                    </h3>
                  </div>
                  {/* /.card-header */}
                  {this.state.loading === false?<div className="card-body">
                    {this.state.alert?.alertNewExam === 0 && this.state.alert?.alertRejected === 0 && this.state.alert?.alertDelayed === 0?
                    <div className="alert alert-info">
                      <h5>
                        <i className="icon fas fa-info" /> Tudo certo!
                      </h5>
                      Não existem novas atualizações!
                    </div>:undefined}
                    
                    {this.state.alert?.alertNewExam != 0?
                    <a href="/ExamList">
                      <div className="alert alert-info">
                        <h5>
                          <i className="icon fas fa-info" /> Novos exames!
                        </h5>
                        Existe {this.state.alert?.alertNewExam} novo(s) exame(s) pendente(s) de laudo!
                      </div>
                    </a>:undefined}
                    {this.state.alert?.alertDelayed != 0?
                    <a href="/ExamList">
                      <div className="alert alert-warning">
                        <h5>
                          <i className="icon fas fa-exclamation-triangle" />{" "}
                          Exames pendentes!
                        </h5>
                        Existem {this.state.alert?.alertDelayed} exame(s) pendente(s) em atraso!
                      </div>
                    </a>:undefined}
                    {this.state.alert?.alertRejected != 0?
                    <a href="/ExamListFilter/REJEITADO">
                      <div className="alert alert-danger">
                        <h5>
                          <i className="icon fas fa-ban" /> Exames Rejeitados!
                        </h5>
                        Existe {this.state.alert?.alertRejected} exame(s) rejeitado(s), por favor verifique!
                      </div>
                    </a>:undefined}
                    
                  </div>:<div className="card-body"><div style={{display: 'flex', justifyContent: 'center', margin: "40px"}}> <Spin size='large' /></div></div>}
                  {/* /.card-body */}
                </div>

                {/* /.card */}
              </div>
              {/* /.col-md-6 */}
            </div>
            {/* /.row *
            <div className="row">
              <div className="col-12">
                {/* interactive chart *
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="far fa-chart-bar" />
                      Exames em tempo real
                    </h3>
                    <div className="card-tools">
                      Tempo real&nbsp;
                      <div className="btn-group" id="realtime" data-toggle="btn-toggle">
                        <button type="button" className="btn btn-default btn-sm active" data-toggle="on">On</button>
                        <button type="button" className="btn btn-default btn-sm" data-toggle="off">Off</button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div id="interactive" style={{height: 300}} />
                  </div>
                  {/* /.card-body*
                </div>
                {/* /.card *
              </div>
              {/* /.col *
            </div>
             .row */}
            {localStorage.getItem("role") != "company"?
            <div className="row">
              <div className="col-md-12">
                <iframe
                    src={state.urlB}
                    frameborder="0"
                    width="100%"
                    height="600px"
                    allowtransparency
                ></iframe>
              </div>
              {/* /.col */}
            </div>:
            <div className="row">
              <div className="col-md-6">
                <iframe
                    src={state.urlB}
                    frameborder="0"
                    width="100%"
                    height="600px"
                    allowtransparency
                ></iframe>
              </div>
              <div className="col-md-6">
                <iframe 
                    src={state.urlC}
                    frameborder="0"
                    width="100%"
                    height="600px"
                    allowtransparency
                ></iframe>
              </div>
            {/* /.col */}
            </div>
            }
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
    );
  }
}
