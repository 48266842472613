import React, { useState, useContext } from 'react'
import StoreContext from '~/components/Store/Context'
import { useHistory } from 'react-router-dom'
import axiosInstance from '~/helpers/axios'
import Amplify from 'aws-amplify'
import amplify_config from '~/amplify-config'
import { Auth, Cache } from 'aws-amplify';
import axios from 'axios'
import policyPrivacy from "../utils/Politica_Privacidade_Hillrom.pdf"

Amplify.configure(amplify_config);

function initialState(){
    return {username:'',password:'',loginStatus:''}
}

const Login = () => {
    const [values, setValues] = useState(initialState)
    const { setToken, setTermAccess } = useContext(StoreContext)
    const history = useHistory()
    
    function onChange(event){
        const {value, name} = event.target
        setValues({
            ...values,
            [name]:value
        })
    }
    function submit(e){
        setValues({values,loginStatus:'loading'})
        axiosInstance.put("/user/tcle").then(res=>{
            setTermAccess({ termAccess: 0 })
            setValues({values,loginStatus:'loading'})
            return window.location.replace("/")
        }).catch(err => {
            setValues({values,loginStatus:'error'})
            console.log(err)
        })
    }
    function LoginFailed(props){
        var status = props.status
        if(status=='loading') return <div className="fa-2x d-flex justify-content-center"><i className="fas fa-spinner fa-spin"></i></div>
        if(status=='error') return <div className="alert alert-danger disabled">Erro no servidor, clique em rejeitar e tente mais tarde.</div>
        return null
    }
    return (
        <div className="login-box" style={{width: "70vw", marginTop: "10px"}}>
            <div className="card">
                <div className="card-body login-card-body">
                    <embed src={policyPrivacy+"#toolbar=0&navpanes=0&scrollbar=0"} width="100%" height="700px" type="application/pdf" />
                    <p>Eu aceito os <strong>Termos de Uso</strong> e a <strong>Política de Privacidade</strong> da Baxter®/Hillrom® </p>
                    {values.loginStatus != 'loading'?<LoginFailed status={values.loginStatus} />: undefined}
                    {values.loginStatus === 'loading'?
                    <LoginFailed status={values.loginStatus}/>:
                    <div className="row justify-content-md-center" >
                        {/* /.col */}
                        <div className="w-100 d-flex justify-content-center pt-2">
                            <button className="btn btn-primary mr-2" onClick={submit}>Aceitar</button>
                            <button className="btn btn-secondary" onClick={() => {sessionStorage.clear(); window.location.href = "/Login"}}>Rejeitar</button>
                        </div>
                        {/* /.col */}
                    </div>
                    }
                    
                </div>
                {/* /.login-card-body */}
            </div>
        </div>
    )
}
export default Login
