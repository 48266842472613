import React from "react";

export default function SidebarCompany() {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <div className="brand-link">
          <img
            src={process.env.PUBLIC_URL+"/dist/img/telescribe-logo.png"}
            alt="AdminLTE Logo"
            className="brand-image"
          />
          <span className="brand-text font-weight-light">TeleScribe</span>
        </div>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={process.env.PUBLIC_URL+"/dist/img/avatar5.png"}
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info" style={{color: "white"}}>
              {localStorage.getItem("name")?localStorage.getItem("name"):"CENTRAL"}
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="nav-icon fas fa-home" />
                  <p>Home</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/DashboardCentral" className="nav-link">
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>Business Intelligence</p>
                </a>
              </li>
              
              <li className="nav-header">GERENCIAMENTO</li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-file-medical" />
                  <p>
                    Exames
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/ExamList" className="nav-link px-5">
                      <p>Todos</p>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="/Abonado" className="nav-link px-5">
                      <p>Abonos Pendentes</p>
                    </a>
                  </li> */}
                </ul>
              </li>
              
              <li className="nav-header">Configurações</li>
              <li className="nav-item">
                <a href="/ChangePassword" className="nav-link">
                  <i className="nav-icon fas fa-lock" />
                  <p>Alterar Senha</p>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
