import React, { useState, useContext } from 'react'
import axiosInstance from '~/helpers/axios'

const ChangePassword = () => {
    const [senhaAtual, setSenhaAtual] = useState("")
    const [novaSenha, setNovaSenha] = useState("")
    const [confirmarSenha, setConfirmarSenha] = useState("")
    const [status, setStatus] = useState("")

    const data = {
        senha_antiga: senhaAtual,
        senha_nova: novaSenha
    }

    function trocarSenha(){

        if(confirmarSenha === "" || novaSenha === "" || senhaAtual === ""){
            setStatus('fill')
        }else if(confirmarSenha != novaSenha){
            setStatus('different')
        }else{
            setStatus('loading')
            console.log(JSON.stringify(data))
            axiosInstance.put('/user/trocar-senha',JSON.stringify(data)).then(res => {
                setConfirmarSenha("")
                setNovaSenha("")
                setSenhaAtual("")
                sessionStorage.clear();
                window.location.reload();
            }).catch(err=>{
                setStatus('')
                if(err.response.status === 401){
                    setConfirmarSenha("")
                    setNovaSenha("")
                    setSenhaAtual("")
                    setStatus('actualwrong')
                }else if(err.response.status === 500){
                    setConfirmarSenha("")
                    setNovaSenha("")
                    setSenhaAtual("")
                    setStatus('servererror')
                }
            })
        }

        
    }

    function AlterarFailed(props){
        var status = props.status
        if(status=='fill') return <div className="alert alert-warning disabled">Preencha todos os campos</div>
        if(status=='different') return <div className="alert alert-warning disabled">Senhas diferentes!</div>
        if(status=='actualwrong') return <div className="alert alert-warning disabled">Senha atual errada!</div>
        if(status=='servererror') return <div className="alert alert-warning disabled">Erro no servidor, tente novamente em breve</div>
        return null
    }
    
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2" style={{justifyContent: 'center', alignItems: 'center'}}>
                        <div className="card" style={{width: 350, marginTop: "15%"}}>
                            <div className="card-body login-card-body">
                                <p className="login-box-msg">Altere sua senha</p>
                                <form action="/" method="post">
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Senha Atual" name="passwordActual" onChange={(e)=>setSenhaAtual(e.target.value)} value={senhaAtual}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Senha Nova" name="passwordNew" onChange={(e)=>setNovaSenha(e.target.value)} value={novaSenha}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Confirme a Senha" name="passwordConfirma" onChange={(e)=>setConfirmarSenha(e.target.value)} value={confirmarSenha}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    {status === ""?undefined:<AlterarFailed status={status}/>}
                                    <div className="row justify-content-md-center">
                                        {/* /.col */}
                                        {status === "loading"?
                                        <div className="fa-2x d-flex justify-content-center"><i className="fas fa-spinner fa-spin"></i></div>:
                                        <div className="col-7">
                                            <button onClick={trocarSenha} type='button' className="btn btn-primary btn-block">Alterar Senha</button>
                                        </div>}
                                        {/* /.col */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ChangePassword
