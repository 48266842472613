import React, { Component } from "react";
import axiosInstance from "~/helpers/axios";
import {withRouter} from 'react-router-dom';
import Inputmask from "inputmask"

class ClinicEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props:props,
        idClinica:"",
        razaoSocial:"",
        idIntegracao: null,
        nomeFantasia: "",
        cnpj: "",
        cep: "",
        estado:"",
        cidade: "",
        complemento: "",
        numero: "",
        bairro: "",
        rua: "",
        responsavel: "",
        email: "",
        telefone: "",
        telefoneCelular: "",
        modalidadeHolter: null,
        modalidadeEcg:null,
        modalTitle:"Aguardando resposta do servidor...",
        modalText:"",
        modalClass:"",
        intervalo:null,
        companies: []
    };
    this.sendButton = this.sendButton.bind(this)
    this.onChange = this.onChange.bind(this)
    this.closeButton = this.closeButton.bind(this)
    this.homeButton = this.homeButton.bind(this)
  }
  componentDidMount(){
    var dataLoad = this.props.location.state
    console.log(dataLoad)
    this.setState({
      idClinica:dataLoad.idClinica,
      razaoSocial:dataLoad.razaoSocial,
      idIntegracao: dataLoad.idIntegracao,
      nomeFantasia: dataLoad.nomeFantasia,
      cnpj: dataLoad.cnpj,
      cep: dataLoad.cep,
      estado:dataLoad.estado,
      cidade: dataLoad.cidade,
      complemento: dataLoad.complemento,
      numero: dataLoad.numero,
      bairro: dataLoad.bairro,
      rua: dataLoad.rua,
      responsavel: dataLoad.responsavel,
      email: dataLoad.email,
      telefone: dataLoad.telefone,
      telefoneCelular: dataLoad.telefoneCelular,
      modalidadeHolter: dataLoad.modalidadeHolter,
      modalidadeEcg: dataLoad.modalidadeEcg,
      intervalo: dataLoad.intervalo
    })
    var cel = document.getElementById("telefoneCelular")
    var tel = document.getElementById("telefone")
    var cnpj = document.getElementById("cnpj")
    var celMask = new Inputmask("(99) 99999 9999");
    var telMask = new Inputmask("(99) 9999 9999");
    var cnpjMask = new Inputmask("99.999.999/9999-99");
    celMask.mask(cel)
    telMask.mask(tel)
    cnpjMask.mask(cnpj)
    var cep = document.getElementById("cep")
    var cepMask = new Inputmask("99999-999");
    cepMask.mask(cep)
    window.$('.select2').select2()
    axiosInstance.get('/company')
    .then((result) =>{
      const companies = result.data
      this.setState({companies})
    })

  }
  onChange(event){
    let { name, value } = event.target;
    if(name=="idIntegracao"){
      this.setState({[name]:parseInt(value)})
    }else{
      this.setState({[name]:value})
    }
  }
  sendButton(){
    const {state} = this
    var data = {
      idClinica: state.idClinica,
      razaoSocial:state.razaoSocial,
      idIntegracao: state.idIntegracao,
      nomeFantasia: state.nomeFantasia,
      cnpj: state.cnpj,
      cep: state.cep,
      estado:state.estado,
      cidade: state.cidade,
      complemento: state.complemento,
      numero: state.numero,
      bairro: state.bairro,
      rua: state.rua,
      responsavel: state.responsavel,
      email: state.email,
      telefone: state.telefone,
      telefoneCelular: state.telefoneCelular,
      modalidadeHolter:state.modalidadeHolter,
      modalidadeEcg:state.modalidadeEcg,
      intervalo: state.intervalo
    }
    console.log(data)
    if(data.razaoSocial == "" || data.cnpj == "" || data.bairro == "" || data.cidade == "" || data.cep == "" || data.rua == "" || data.telefoneCelular == "" || data.email == "" || data.modalidadeHolter == 0 || data.intervalo == 0 || data.nomeFantasia == "" || data.responsavel == "" || data.numero == "" || data.idIntegracao == "0"){
      window.$(".modal-header").addClass("bg-danger")
      this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
    }else{
      window.$(".modal-header").removeClass("bg-danger")
      this.setState({modalTitle:"Aguardando resposta do servidor...",modalText:"",sendError:false})
      axiosInstance.put('/clinic/'+data.idClinica,data)
      .then((response)=>{
        console.log(response);
        window.$(".modal-header").removeClass("bg-danger")
        window.$(".modal-header").addClass("bg-success")
        this.setState({modalTitle:"Sucesso!",modalText:"Dados Inseridos com sucesso!",sendError:false})
      }).catch((error)=>{
        console.log(error);
        window.$(".modal-header").addClass("bg-danger")
        this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
      })
    }
    
  }
  closeButton(){
    if(this.state.sendError){
      window.$(".modal-header").removeClass("bg-danger")
      this.setState({modalTitle:"Aguardando resposta do servidor...",modalText:"",sendError:false})
    }else{
      this.state.props.history.push('/ClinicList')
    }
  }
  homeButton(){
    this.state.props.history.push('/')
  }
  render() {
    const {state} = this
    return (
      <div class="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Editar Registro de Clínica</h1>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section class="content">
          <div className="container-fluid">
            
            <div className="card card-default">
              <div className="card-body">
              <p>Campos com <span style={{color:"red"}}>*</span> são obrigatórios</p>
                <h2>Dados</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group required form-row">
                      <div className="col-8">
                        <label className="control-label">CNPJ</label>
                        <input className="form-control" id="cnpj" type="text" name="cnpj" onChange={this.onChange} value={state.cnpj}/>
                      </div>
                      <div className="col-4">
                        <label className="control-label">ID de Integração</label>
                        <input className="form-control" type="text" name="idIntegracao" onChange={this.onChange} onkeypress={this.onlyNumberKey} value={state.idIntegracao || this.setState({idIntegracao:"0"})}/>
                      </div>
                    </div>
                    <div className="form-group required">
                      <label className="control-label">Razão Social</label>
                      <input className="form-control" type="text" name="razaoSocial" onChange={this.onChange} value={state.razaoSocial}/>
                    </div>
                    {/* /.form-group */}
                    <div className="form-group required">
                      <label className="control-label">Nome Fantasia</label>
                      <input className="form-control" type="text" name="nomeFantasia" onChange={this.onChange} value={state.nomeFantasia}/>
                    </div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <div className="form-group required">
                      <div className="col-6">
                        {/* <div className="form-row">
                          <label className="control-label">Tempo para abono de exame</label>
                          <input className="form-control col-4" type="text" name="tempoAbono"/>
                          <p className="col-4">dias</p>
                        </div> */}
                      </div>
                    </div>
                  <div className="card text-white">
                      <div className="card-body text-dark">
                        <p className="card-text">Selecione a central de laudo para as modalidades.</p>
                        <div className="form-group required">
                          <label className="control-label">HOLTER</label>
                            <select className="custom-select" name="modalidadeHolter" onChange={this.onChange} value={state.modalidadeHolter} placeholder="Carregando dados...">
                              <option ngValue="0">Selecione uma central...</option>
                              {this.state.companies?.map(company => <option key={company.idEmpresa} value={company.idEmpresa}>{company.nomeFantasia}</option>)}
                            </select>
                        </div>
                        <div className="form-group required">
                          <label className="control-label">Tempo para laudo</label>
                            <select className="custom-select" name="intervalo" onChange={this.onChange} value={state.intervalo} placeholder="Carregando dados...">
                              <option ngValue="0">Selecione intervalo...</option>
                              <option value={24}>24 Horas</option>
                              <option value={48}>48 Horas</option>
                              <option value={72}>72 Horas</option>
                            </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                <div className="row">
                <div className="col-md-6">
                    <h3>Endereço</h3>
                    <div className="form-group required required form-row">
                      <div className="col-9">
                        <label className="control-label">Logradouro</label>
                        <input className="form-control" type="text" name="rua" onChange={this.onChange} value={state.rua}/>
                      </div>
                      <div className="col-3">
                        <label className="control-label">Número</label>
                        <input className="form-control" type="text" name="numero" onChange={this.onChange} value={state.numero}/>
                      </div>
                    </div>
                    <div className="form-group required">
                      <label>Complemento</label>
                      <input className="form-control" type="text" name="complemento" onChange={this.onChange} value={state.complemento}/>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-6">
                        <label className="control-label">Cidade</label>
                        <input className="form-control" type="text" name="cidade" onChange={this.onChange} value={state.cidade}/>
                      </div>
                      <div className="col-6">
                        <label className="control-label">Bairro</label>
                        <input className="form-control" type="text" name="bairro" onChange={this.onChange} value={state.bairro}/>
                        </div>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-8">
                        <label className="control-label">Estado</label>
                        <select className="custom-select" name="estado" onChange={this.onChange} value={state.estado}>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="EX">Estrangeiro</option>
                        </select>
                      </div>
                      <div className="col-4">
                        <label className="control-label">CEP</label>
                        <input className="form-control" type="text" id="cep" name="cep" onChange={this.onChange} value={state.cep}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <h3>Contato</h3>
                    <div className="form-group required">
                      <label className="control-label">Responsável</label>
                      <input className="form-control" type="text" name="responsavel" onChange={this.onChange} value={state.responsavel}/>
                    </div>
                    <div className="form-group required">
                      <label className="control-label">Email</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fas fa-envelope" /></span>
                        </div>
                        <input disabled type="email" id="email" className="form-control" placeholder="Email" name="email" onChange={this.onChange} value={state.email}/>
                      </div>
                    </div>
                    <div className="form-group form-row">
                      <div className="col-9">
                        <label className="control-label">Telefone Fixo</label>
                        <input className="form-control" type="text" id="telefone" name="telefone" onChange={this.onChange} value={state.telefone}/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label className="control-label">Telefone Celular</label>
                        <input className="form-control" type="text" id="telefoneCelular" name="telefoneCelular" onChange={this.onChange} value={state.telefoneCelular}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                
              </div>
              {/* /.card-body */}
              <div class="card-footer">
                  <button class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Salvar</button>
                  <a href="/ClinicList"><button class="btn btn-secondary ml-2">Cancelar</button></a>
              </div>
            </div>
            {/* /.card */}
          </div>
          <div className="modal fade" id="modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{state.modalTitle}</h4>
                    </div>
                    <div className="modal-body">
                        <p>{state.modalText}</p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={this.closeButton} data-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-primary" onClick={this.homeButton} data-dismiss="modal">Retornar a Home</button>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(ClinicEditForm);