import React, { Component } from "react";
import Inputmask from "inputmask"
import axiosInstance from '~/helpers/axios'
import {withRouter} from 'react-router-dom';

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props:props,
      clinicas: [],
      empresas: [],
      nome:"",
      crm: "",
      dataNascimento: "",
      perfil: "",
      cep: "",
      estado: "AC",
      cidade: "",
      rua: "",
      email: "",
      telefoneCelular: "",
      telefoneResidencial: "",
      bairro: "",
      complemento: "",
      numero: "",
      cpf: "",
      rg: "",
      modalTitle:"Aguardando resposta do servidor...",
      modalText:""
    };
    this.sendButton = this.sendButton.bind(this)
    this.onChange = this.onChange.bind(this)
    this.closeButton = this.closeButton.bind(this)
    this.homeButton = this.homeButton.bind(this)
  }
  componentDidMount(){
    const {state} = this
    axiosInstance.get('/clinic')
    .then((result) =>{
      var clinic = []
      result.data.forEach(element => {
        //console.log(element)
        clinic.push({
          id:element.idClinica,
          text:element.nomeFantasia
        })
      });
      window.$('.select-clinic').select2({
        data:clinic
      })
    })
    axiosInstance.get('/company')
    .then((result) =>{
      var company = []
      result.data.forEach(element => {
        //console.log(element)
        company.push({
          id:element.idEmpresa,
          text:element.nomeFantasia
        })
      });
      window.$('.select-company').select2({
        data:company
      })
    })
    window.$('.select-clinic').select2().on("select2:select", function (e){
      state.clinicas.push(parseInt(e.params.data.id))
      console.log(state.clinicas)
    })
    window.$('.select-clinic').select2().on("select2:unselect", function (e){
      state.clinicas.splice(state.clinicas.indexOf(e.params.data.id),1)
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:select", function (e){
      state.empresas.push(parseInt(e.params.data.id))
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:unselect", function (e){
      state.empresas.splice(state.clinicas.indexOf(e.params.data.id),1)
      console.log(state.clinicas)
    })
  }
  onChange(event){
    let { name, value } = event.target;
    this.setState({[name]:value})
  }
  sendButton(){
    const {state} = this
    function dataNasc(data){
      var dataSplit = data.split("/")
      return dataSplit[2]+"-"+dataSplit[1]+"-"+dataSplit[0]+" 00:00"
    }
    var data = {
      clinicas: state.clinicas,
      empresas: state.empresas,
      nome:state.nome,
      crm: state.crm,
      dataNascimento: dataNasc(state.dataNascimento),
      perfil: state.perfil,
      cep: state.cep,
      estado: state.estado,
      cidade: state.cidade,
      rua: state.rua,
      email: state.email,
      telefoneCelular: state.telefoneCelular,
      telefoneResidencial: state.telefoneResidencial,
      bairro: state.bairro,
      complemento: state.complemento,
      numero: state.numero,
      cpf: state.cpf,
      rg: state.rg
    }
    axiosInstance.post('/doctor',data)
    .then((response)=>{
      console.log(response)
      window.$(".modal-header").addClass("bg-success")
      this.setState({modalTitle:"Sucesso!",modalText:"Dados Inseridos com sucesso!",sendError:false})
    }).catch((error)=>{
      console.log(error)
      window.$(".modal-header").addClass("bg-danger")
      this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
    })
  }
  closeButton(){
    if(this.state.sendError){
      window.$(".modal-header").removeClass("bg-danger")
      this.setState({modalTitle:"Aguardando resposta do servidor...",modalText:"",sendError:false})
    }else{
      this.state.props.history.push('/DoctorList')
    }
  }
  homeButton(){
    this.state.props.history.push('/')
  }
  render() {
    const {state} = this
    return (
      <div class="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Agendamento</h1>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section class="content">
          <div className="container-fluid">
            <div className="row">
            <div className="card card-default col-md-5">
              <div className="card-body">
              <p>Campos com <span style={{color:"red"}}>*</span> são obrigatórios</p>
                <h2>Dados</h2>
                <div className="row">
                  <div className="col-md-12">
                    
                    <div className="form-group required form-row">
                      <div className="col-4">
                        <label className="control-label">ID</label>
                        <input className="form-control" type="text" id="cpf" name="cpf" onChange={this.onChange} value={state.cpf}/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-6">
                        <label className="control-label">Nome</label>
                        <input className="form-control" type="text" name="nome" onChange={this.onChange} value={state.nome}/>
                      </div>
                      <div className="col-6">
                        <label className="control-label">Sobrenome</label>
                        <input className="form-control" type="text" name="sobrenome" onChange={this.onChange} value={state.sobrenome}/>
                      </div>
                    </div>
                    {/* /.form-group */}
                    <div className="form-group required required form-row">
                      <div className="col-5">
                        <label className="control-label required">Data de Nascimento</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fas fa-birthday-cake" /></span>
                          </div>
                          <input type="text" className="form-control" id="dataNascimento" name="dataNascimento" onChange={this.onChange} value={state.dataNascimento}/>
                        </div>
                      </div>
                      <div className="col-7">
                        <label className="control-label">Gênero Biológico</label>
                        <select className="custom-select" name="estado" onChange={this.onChange} value={state.estado}>
                          <option value="AC">Masculino</option>
                          <option value="AL">Feminino</option>
                          <option value="AP">Não Definido</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className="form-group required required form-row">
                      <div className="col-8">
                        <label className="control-label">Óculos</label>
                        <select className="custom-select" name="estado" onChange={this.onChange} value={state.estado}>
                          <option value="AC">Não usa</option>
                          <option value="AL">Óculos</option>
                          <option value="AP">Lente</option>
                          <option value="AP">Desconhecido</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group form-row">
                      <div className="col-10">
                        <label className="control-label">Local</label>
                        <input className="form-control" type="text" name="crm" onChange={this.onChange} value={state.crm}/>
                      </div>
                    </div>
                    {/* /.input group */}
                    <div className="form-group required required form-row">
                    <div className="col-6">
                        <label className="control-label">Data/Hora do Agendamento</label>
                        <input className="form-control" type="text" name="nome" onChange={this.onChange} value="01/04/2021:13:00:00" disabled/>
                    </div>
                    <div className="col-6">
                        <label className="control-label">Data/Hora de Atendimento</label>
                        <input className="form-control" type="text" name="nome" onChange={this.onChange} value="06/04/2021:16:30:00"/>
                    </div>
                    </div>

                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div class="card-footer">
              <button class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Registrar</button>
              </div>
            </div>
            {/* /.card */}
            <div className="col-md-1"></div>
            <div className="card card-default col-md-6">
              <div className="card-header">
                <h3 className="card-title">Agendados</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <table id="example2" className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Atendimento</th>
                      <th>Nome</th>
                      <th>Óculos</th>
                      <th>Idade</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>06/04/2021:16:35:00</td>
                      <td>Marcos Vinicus
                      </td>
                      <td>Nenhum</td>
                      <td> 4</td>
                      <td><i class="fas fa-trash-alt"></i> - <i class="fas fa-edit"></i></td>
                    </tr>
                    <tr>
                      <td>06/04/2021:16:35:00</td>
                      <td>John Doe
                      </td>
                      <td>Nenhum</td>
                      <td> 4</td>
                      <td><i class="fas fa-trash-alt"></i> - <i class="fas fa-edit"></i></td>
                    </tr>
                    <tr>
                      <td>06/04/2021:16:35:00</td>
                      <td>Paulo Reis
                      </td>
                      <td>Óculos</td>
                      <td> 8</td>
                      <td><i class="fas fa-trash-alt"></i> - <i class="fas fa-edit"></i></td>
                    </tr>
                    <tr>
                      <td>06/04/2021:16:35:00</td>
                      <td>Diego Pereira</td>
                      <td>Lente</td>
                      <td> 9</td>
                      <td><i class="fas fa-trash-alt"></i> - <i class="fas fa-edit"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* /.card-body */}
              <div class="card-footer justify-content-between">
              <button class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Exportar CSV</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Transmitir para SpotVision</button>
              
              </div>
            </div>
            </div>
          </div>
          <div className="modal fade" id="modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{state.modalTitle}</h4>
                    </div>
                    <div className="modal-body">
                        <p>{state.modalText}</p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={this.closeButton} data-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-primary" onClick={this.homeButton} data-dismiss="modal">Retornar a Home</button>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(Appointment);