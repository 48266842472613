import React from "react";

const TermLocal = props => {
  return (
    <div style={{width: "100vw", minHeight: "100vh", backgroundColor: "#5369e5", display: "flex", justifyContent: "center", alignItems: "center"}}>
        {props.children}
    </div>
  );
};

export default TermLocal;
