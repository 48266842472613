import React from 'react'
import axiosInstance from '~/helpers/axios'

export default class TestPage extends React.Component {
    componentDidMount(){
        axiosInstance.get("/exame/rejected/?limit=10&page=1")
                        .then((response)=>{
                            console.log(response)
                        }).catch((error)=>{
                            console.log(error)
                        })
    }
    render() {
        return (
            <div class="content-wrapper">
                <section class="content">
                <iframe
                    src="http://192.168.88.238:3000/public/dashboard/e4304bec-f95f-4103-9cc8-a98041d0a727"
                    frameborder="0"
                    width="100%"
                    height="800"
                    allowtransparency
                ></iframe>
                </section>
            </div>
            
        )
    }
}
