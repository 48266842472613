import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom'
import StoreContext from 'src/components/Store/Context'

import Dashboard from '~/layouts/Dashboard/Dashboard'

const DashboardRoutes = ({component: Component, ...rest}) => {
    const { token, termAccess} = useContext(StoreContext)
    return (
        <Route
            {...rest}
            render={() => token && termAccess?.termAccess === 0 //O certo é 0
                ?<Dashboard> <Component {...rest} /> </Dashboard>
                : <Redirect to="/Login" />
            }
            />
    )
}

export default DashboardRoutes