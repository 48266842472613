import React, { Component } from 'react';
import { Route } from 'react-router-dom'

import Forget from './Forget';

const ForgetRoutes = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            <Forget>
                <Component {...props} />
            </Forget>
        )} />
    )
}

export default ForgetRoutes