import React from 'react'
import Context from './Context'
import useStorage from '../../utils/useStorage'

const StoreProvider = ({children}) => {
    const [token, setToken] =  useStorage('token')
    const [termAccess, setTermAccess] = useStorage('termAccess')
    return(
        <Context.Provider
            value={{
                token,setToken,termAccess,setTermAccess
            }}
        >
            {children}
        </Context.Provider>
    )
}

export default StoreProvider