import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'
import StoreContext from 'src/components/Store/Context'
import TermLocal from './TermLocal';

const TermLocalRoutes = ({component: Component, ...rest}) => {
    const { token, termAccess } = useContext(StoreContext)
    return (
        <Route
            {...rest}
            render={() => token && termAccess?.termAccess === 1 
                ?<TermLocal> <Component {...rest} /> </TermLocal>
                : <Redirect to="/Login" />
            }
            />
    )
}

export default TermLocalRoutes