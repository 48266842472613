import React, { Component } from "react";

export default class ClinicaDashboard extends Component {
  componentDidMount() {
    var $  = window.$
    $(function () {
      var data        = [],
      totalPoints = 100
      var data2        = []


    function labelFormatter(label, series) {
      return '<div style="font-size:13px; text-align:center; padding:2px; color: #fff; font-weight: 600;">'
        + label
        + '<br>'
        + Math.round(series.percent) + '%</div>'
    }
    })
  }
  render() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Business Intelligence</h1>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" style={{display: "flex", flexWrap: "wrap"}}>
                <iframe
                      src={"https://metabase.telescribecardio.com/public/question/dbcf5338-6599-4c5b-b285-e17ead5429c1?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                      frameborder="0"
                      width="100%"
                      style={{'min-height':'600px', borderRadius: 10}}
                      allowtransparency
                ></iframe>
              </div>
              <div className="col-12" style={{display: "flex", flexWrap: "wrap", marginTop: 30, marginBottom: 10, justifyContent: "space-between"}}>
                <iframe
                      src={"https://metabase.telescribecardio.com/public/question/a73967ae-9885-4563-a8d1-8dc7f6255ee6?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                      frameborder="0"
                      width="49%"
                      style={{'min-height':'500px', borderRadius: 10}}
                      allowtransparency
                ></iframe>
                <iframe
                      src={"https://metabase.telescribecardio.com/public/question/4895a6de-ff6b-4d27-8daf-062a5cf4939c?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                      frameborder="0"
                      width="49%"
                      style={{'min-height':'500px', borderRadius: 10}}
                      allowtransparency
                ></iframe>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
    );
  }
}
