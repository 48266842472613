import axios from 'axios'

const baseUrl = "https://imp4gbu3g5.execute-api.us-east-1.amazonaws.com/prod/"
var token = JSON.parse(sessionStorage.getItem("token"))
var axiosInstance = null;
if(token!=null){
    axiosInstance = axios.create({
        baseURL:baseUrl,
        headers :{
            'Authorization': token.token
        }
    })
}else {
    axiosInstance = axios.create({
        baseURL:baseUrl
    })
}
export default axiosInstance