import React, { useState, useContext } from 'react'
import StoreContext from '~/components/Store/Context'
import { useHistory } from 'react-router-dom'
import axiosInstance from '~/helpers/axios'
import Amplify from 'aws-amplify'
import amplify_config from '~/amplify-config'
import { Auth, Cache } from 'aws-amplify';
import axios from 'axios'

Amplify.configure(amplify_config);

function initialState(){
    return {username:'',password:'',loginStatus:''}
}

const Login = () => {
    const [values, setValues] = useState(initialState)
    const { setToken, setTermAccess } = useContext(StoreContext)
    const [termos, setTermos] = useState(false)
    const history = useHistory()
    
    function onChange(event){
        const {value, name} = event.target
        setValues({
            ...values,
            [name]:value
        })
    }
    function submit(e){
        e.preventDefault()

        var username = values.username
        var password = values.password

        Auth.signIn({
            username,
            password
        }).then((cognitoUser) => {
            setValues({values,loginStatus:'loading'})
            Auth.currentSession()
                .then((userSession) => {
                    axios.get('https://imp4gbu3g5.execute-api.us-east-1.amazonaws.com/prod/user/info',
                    {
                        headers: {
                            'Authorization':userSession.accessToken.jwtToken
                        }
                    }).then((response)=>{
                        localStorage.setItem("role",response.data.type)
                        localStorage.setItem("access",response.data.idAccess)
                        localStorage.setItem("name",response.data.name)
                        setToken({ token: userSession.accessToken.jwtToken })
                        if(response.data.primeiroAcesso === 0 /*O certo é 0*/){
                            setTermAccess({ termAccess: response.data.primeiroAcesso })
                            return window.location.replace("/")
                        }else{
                            setTermAccess({ termAccess: response.data.primeiroAcesso })
                            return window.location.replace("/Term")
                        }
                    })
                })
                .catch((err) => {
                    console.log(err)
                    return { error: 'Usuário ou senha inválidos' }
                });
        }).catch((err) => {
            console.log(err)
            setValues({values,loginStatus:'error'})
            return { error: 'Usuário ou senha inválidos'}
        })
        setValues(initialState)
    }
    function LoginFailed(props){
        var status = props.status
        if(status=='loading') return <div className="fa-2x d-flex justify-content-center"><i className="fas fa-spinner fa-spin"></i></div>
        if(status=='error') return <div className="alert alert-danger disabled">Usuário ou senha inválidos!</div>
        if(status=='termError') return <div className="alert alert-danger disabled">Aceite os termos antes de fazer Login.</div>
        return null
    }
    return (
        <div className="login-box">
            <div className="login-logo">
                <img src={process.env.PUBLIC_URL+"/dist/img/telescribe-logo.png"} alt="Hillrom Logo" className="login-brand-logo elevation-3" /> TeleScribe<span style={{fontSize: "16px"}}> v0.1.0</span>
                {/* <img src={process.env.PUBLIC_URL+"/dist/img/Hillrom_TM.png"} alt="TradeMark" className="login-brand-name" />*/}
            </div>
            {/* /.login-logo */}
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Faça login para iniciar sua sessão</p>
                    <form action="/" method="post">
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder="Usuário" name="username" onChange={onChange} value={values.username}/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className="form-control" placeholder="Senha" name="password" onChange={onChange} value={values.password}/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                </div>
                            </div>
                        </div>
                        <LoginFailed status={values.loginStatus} />
                        <div className="row justify-content-md-center">
                            {/* /.col */}
                            <div className="col-4">
                                <button className="btn btn-primary btn-block" onClick={submit}>Login</button>
                            </div>
                            {/* /.col */}
                        </div>
                    </form>
                    <div className="row justify-content-md-center">
                    <p className="mb-1">
                        <a href="/Email">Esqueci minha senha </a>
                    </p>
                    </div>
                </div>
                {/* /.login-card-body */}
            </div>
        </div>
    )
}
export default Login
