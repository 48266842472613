import React, { Component } from 'react'
import { useParams } from 'react-router-dom'

export default function ViewLog() {

        const {id} = useParams();
        
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Trilha de Auditoria</h1>
                        </div>
                    </div>
                    
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Detalhes do evento</h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                            <h3>Origem</h3>
                            <strong>Nome de Usuário: </strong> ADMIN <br />
                            <strong>ID de usuário: </strong> {id} <br />
                            <strong>IP de Origem: </strong> 177.185.46.161 <br />
                            <strong>Acesso em: </strong> N/D <br />
                            <strong>Token de Acesso: </strong> N/D <br />
                            <hr />
                            <h3>Execução</h3>
                            <strong>Evento: </strong> Novo Registro<br />
                            <strong>Tipo: </strong> Médico <br />
                            <strong>ID: </strong> 1 <br />
                            <strong>Timestamp: </strong> 09/03/2021 18:31 <br />
                            <hr />
                            <div class="callout callout-success elevation-1">
                                <h3>Realação de dados inseridos</h3>
                                <hr />
                                <p>
                                    <strong>CNPJ:</strong>  27.589.479/0001-08 <br />
                                    <strong>ID INTEGRAÇÃO:</strong> 1 <br />
                                    <strong>RAZÃO SOCIAL:</strong> CLINICA LAUDO BOM  <br />
                                    <strong>NOME FANTASIA:</strong> LAUDO BOM <br />
                                    <strong>LOGRADOURO:</strong> RUA HILLROM <br />
                                    <strong>NÚMERO:</strong> 1190 <br />
                                    <strong>COMPLEMENTO:</strong> CJ. 122<br /> 
                                    <strong>BAIRRO:</strong> CENTRO <br />
                                    <strong>CIDADE:</strong> SÃO PAULO	 <br />
                                    <strong>ESTADO:</strong> SP <br />
                                    <strong>CEP:</strong> 09102-032 <br />
                                    <strong>RESPONSÁVEL:</strong> DR. MARCOS AURELIO SILVEIRA <br />
                                    <strong>E-MAIL:</strong> testeHR@interopera.com.br <br />
                                    <strong>TELEFONE FIXO:</strong> (11) 4221-2255 <br />
                                    <strong>TELEFONE CELULAR:</strong> (11) 95577-0099 <br />
                                </p>
                            </div>
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
        )
}
