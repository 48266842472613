import React, { Component } from 'react';
import { Route } from 'react-router-dom'

import Login from '~/layouts/Login/Login'

const LoginRoutes = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            <Login>
                <Component {...props} />
            </Login>
        )} />
    )
}

export default LoginRoutes