import React, { Component } from "react";
import Inputmask from "inputmask"
import axiosInstance from '~/helpers/axios'
import {withRouter} from 'react-router-dom';
import semImagem from '../../utils/semImagem.svg'

class PractitionerNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeDoArquivo:"Arquivo PNG",
      props:props,
      clinicas: [],
      empresas: [],
      nome:"",
      crm: "",
      dataNascimento: "",
      perfil: "usuário",
      cep: "",
      estado: "AC",
      cidade: "",
      rua: "",
      email: "",
      telefoneCelular: "",
      telefoneResidencial: "",
      bairro: "",
      complemento: "",
      numero: "",
      cpf: "",
      rg: "",
      imagemAssinatura: "",
      modalTitle:"Aguardando resposta do servidor...",
      modalText:""
    };
    this.sendButton = this.sendButton.bind(this)
    this.onChange = this.onChange.bind(this)
    this.closeButton = this.closeButton.bind(this)
    this.homeButton = this.homeButton.bind(this)
  }
  componentDidMount(){
    const {state} = this
    var cel = document.getElementById("telefoneCelular")
    var tel = document.getElementById("telefoneResidencial")
    var cpf = document.getElementById("cpf")
    var nasc = document.getElementById("dataNascimento")
    var cep = document.getElementById("cep")
    var celMask = new Inputmask("(99) 99999 9999");
    var telMask = new Inputmask("(99) 9999 9999");
    var cpfMask = new Inputmask("999.999.999-99");
    var nascMask = new Inputmask("99/99/9999");
    celMask.mask(cel)
    telMask.mask(tel)
    cpfMask.mask(cpf)
    nascMask.mask(nasc)
    var cep = document.getElementById("cep")
    var cepMask = new Inputmask("99999-999");
    cepMask.mask(cep)
    var botao = document.getElementById("salvar-botao")
    botao.disabled = true;
    axiosInstance.get('/clinic')
    .then((result) =>{
      var clinic = []
      result.data.forEach(element => {
        //console.log(element)
        clinic.push({
          id:element.idClinica,
          text:element.nomeFantasia
        })
      });
      window.$('.select-clinic').select2({
        data:clinic
      })
    })
    axiosInstance.get('/company')
    .then((result) =>{
      var company = []
      result.data.forEach(element => {
        //console.log(element)
        company.push({
          id:element.idEmpresa,
          text:element.nomeFantasia
        })
      });
      window.$('.select-company').select2({
        data:company
      })
    })
    window.$('.select-clinic').select2().on("select2:select", (e) => {
      this.setState(prevState => ({
        clinicas: [...prevState.clinicas, parseInt(e.params.data.id)]
      }));
      console.log(state.clinicas)
    })
    window.$('.select-clinic').select2().on("select2:unselect", (e) => {
      this.setState(prevState => ({
        clinicas: prevState.clinicas.filter(id => id !== parseInt(e.params.data.id))
      }));
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:select", (e) => {
      this.setState(prevState => ({
        empresas: [...prevState.empresas, parseInt(e.params.data.id)]
      }));
      console.log(state.empresas)
    })
    window.$('.select-company').select2().on("select2:unselect", (e) => {
      this.setState(prevState => ({
        empresas: prevState.empresas.filter(id => id !== parseInt(e.params.data.id))
      }));
      console.log(state.empresas)
    })
    //Método antigo
    /* window.$('.select-clinic').select2().on("select2:select", function (e){
      state.clinicas.push(parseInt(e.params.data.id))
      console.log(state.clinicas)
    })
    window.$('.select-clinic').select2().on("select2:unselect", function (e){
      state.clinicas.splice(state.clinicas.indexOf(e.params.data.id),1)
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:select", function (e){
      state.empresas.push(parseInt(e.params.data.id))
      console.log(state.empresas)
    })
    window.$('.select-company').select2().on("select2:unselect", function (e){
      state.empresas.splice(state.empresas.indexOf(e.params.data.id),1)
      console.log(state.empresas)
    }) */
  }
  onChange(event){
    let { name, value } = event.target;
    this.setState({[name]:value})

    if(name === "email"){
      var botao = document.getElementById("salvar-botao")
      botao.disabled = true;
      document.getElementById('email').classList.remove('is-valid');
      document.getElementById('email').classList.remove('is-invalid');
    }
  }
  checarEmail(email){
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    document.getElementById('erro-servico').classList.add('d-none');
    if(regex.test(email)){
      document.getElementById('loading-email').classList.remove('d-none');  
      document.getElementById('button-check').classList.add('d-none');
      axiosInstance.post("/user/info/email",{"email":email}).then(res => {
        document.getElementById('loading-email').classList.add('d-none');
        document.getElementById('button-check').classList.remove('d-none');
        if(res.data.exists === false){
          var botao = document.getElementById("salvar-botao")
          botao.disabled = false;
          document.getElementById('email').classList.add('is-valid');
          document.getElementById('email').classList.remove('is-invalid');
        }else{
          var botao = document.getElementById("salvar-botao")
          botao.disabled = true;
          document.getElementById('email').classList.remove('is-valid');
          document.getElementById('email').classList.add('is-invalid');
        }
      }).catch(err => {
        var botao = document.getElementById("salvar-botao")
        botao.disabled = true;
        document.getElementById('loading-email').classList.add('d-none');
        document.getElementById('button-check').classList.remove('d-none');
        document.getElementById('erro-servico').classList.remove('d-none');
      })
    }else{
      document.getElementById('email').classList.add('is-invalid');
      document.getElementById('email').classList.remove('is-valid');
    }
  }
  sendButton(){
    const {state} = this
    function dataNasc(data){
      var dataSplit = data.split("/")
      return dataSplit[2]+"-"+dataSplit[1]+"-"+dataSplit[0]+" 00:00"
    }
    var data = {
      clinicas: state.clinicas,
      empresas: state.empresas,
      nome:state.nome,
      crm: state.crm,
      dataNascimento: dataNasc(state.dataNascimento),
      perfil: "usuario",
      cep: state.cep,
      estado: state.estado,
      cidade: state.cidade,
      rua: state.rua,
      email: state.email,
      telefoneCelular: state.telefoneCelular,
      telefoneResidencial: state.telefoneResidencial,
      bairro: state.bairro,
      complemento: state.complemento,
      numero: state.numero,
      cpf: state.cpf,
      rg: state.rg,
      imagemAssinatura: state.imagemAssinatura
    }
    if(data.cidade == "" || data.clinicas.length == 0 || data.empresas.length == 0 || data.cep == "" || data.nome == "" || data.crm == "" || data.cpf == "" || data.numero == "" || data.bairro == "" || data.telefoneCelular == "" || data.email == "" || data.rua == "" || data.dataNascimento == "undefined-undefined- 00:00" || data.rg == "" || data.imagemAssinatura == ""){
      window.$(".modal-header").addClass("bg-danger")
      this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
    }else{
      window.$(".modal-header").removeClass("bg-danger")
      this.setState({modalTitle:"Aguardando resposta do servidor...",modalText:"",sendError:false})
      axiosInstance.post('/doctor',data)
      .then((response)=>{
        console.log(response)
        window.$(".modal-header").addClass("bg-success")
        this.setState({modalTitle:"Sucesso!",modalText:"Dados Inseridos com sucesso!",sendError:false})
      }).catch((error)=>{
        console.log(error)
        window.$(".modal-header").addClass("bg-danger")
        this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
      })
    }
  }
  transform() {
    const event = document.getElementById("exampleInputFile");
  
    if (event.files.length >= 1) {
      const file = event.files[0];
      const extension = file.name.split(".").pop().toLowerCase();
  
      if (extension === "png") {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 550 && img.height <= 140) {
            this.setState({ nomeDoArquivo: file.name });
  
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = () => {
              const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");
  
              this.setState({ imagemAssinatura: base64String });
            };
          } else {
            alert("A imagem selecionada deve ter a resolução máxima de 550x140 pixels.");
            this.setState({ nomeDoArquivo: "Arquivo PNG", imagemAssinatura: "" });
          }
        };
        img.src = URL.createObjectURL(file);
      } else {
        alert("O arquivo selecionado deve ser uma imagem do tipo PNG.");
        this.setState({ nomeDoArquivo: "Arquivo PNG", imagemAssinatura: "" });
      }
    } else {
      this.setState({ nomeDoArquivo: "Arquivo PNG", imagemAssinatura: "" });
    }
  }  
  /* transform(){
    const event = document.getElementById("exampleInputFile")
    if(event.files.length >= 1){
      this.setState({nomeDoArquivo: event.files[0].name})
      const reader = new FileReader()
      reader.readAsDataURL(event.files[0])
      reader.onload = () =>{
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '')
      
      this.setState({imagemAssinatura: base64String})
      }
    }else{
      this.setState({nomeDoArquivo: "Arquivo PNG", imagemAssinatura: ""})
    }
  } */
  closeButton(){
    if(this.state.sendError){
      window.$(".modal-header").removeClass("bg-danger")
    }else{
      this.state.props.history.push('/DoctorList')
    }
  }
  homeButton(){
    this.state.props.history.push('/')
  }
  render() {
    const {state} = this
    return (
      <div class="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Cadastrar Médico</h1>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section class="content">
          <div className="container-fluid">
            
            <div className="card card-default">
              <div className="card-body">
              <p>Campos com <span style={{color:"red"}}>*</span> são obrigatórios</p>
                <h2>Dados</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group required form-row">
                      <div className="col-8">
                        <label className="control-label">CRM</label>
                        <input className="form-control" type="text" name="crm" onChange={this.onChange} value={state.crm}/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-6">
                        <label className="control-label">CPF</label>
                        <input className="form-control" type="text" id="cpf" name="cpf" onChange={this.onChange} value={state.cpf}/>
                      </div>
                      <div className="col-6">
                        <label className="control-label">RG</label>
                        <input className="form-control" type="text" name="rg" onChange={this.onChange} value={state.rg}/>
                      </div>
                    </div>
                    <div className="form-group required">
                      <label className="control-label">Nome Completo</label>
                      <input className="form-control" type="text" name="nome" onChange={this.onChange} value={state.nome}/>
                    </div>
                    {/* /.form-group */}
                    <div style={{marginBottom: 0}} className="form-group required input-group">
                      <label className="control-label required">Data de Nascimento</label>
                    </div>
                    <div className="form-group required input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><i className="far fa-calendar-alt" /></span>
                        <input type="text" className="form-control" id="dataNascimento" name="dataNascimento" onChange={this.onChange} value={state.dataNascimento}/>
                      </div>
                    </div>
                    {/* /.input group */}

                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    {/* <div className="form-group required">
                      <label className="control-label">Perfil</label>
                      <input className="form-control" type="text" name="perfil" onChange={this.onChange} value={state.perfil}/>
                    </div> */}
                    {/* /.form-group */}
                    <div className="form-group required">
                      <label className="control-label">Centrais Atendidas</label>
                      <div className="select2-indigo">
                      <select className="select-company" multiple="multiple" data-placeholder="Selecione uma ou mais centrais" style={{ width: "100%" }} />
                    </div></div>
                    {/* /.form-group */}
                    <div className="form-group required">
                      <label className="control-label">Clínicas Atendidas</label>
                      <div className="select2-indigo">
                      <select className="select-clinic" multiple="multiple" data-placeholder="Selecione uma ou mais clinicas" style={{ width: "100%" }} />
                    </div></div>
                    {/* /.form-group */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                <div className="row">
                <div className="col-md-6">
                    <h3>Endereço</h3>
                    <div className="form-group required required form-row">
                      <div className="col-9">
                        <label className="control-label">Logradouro</label>
                        <input className="form-control" type="text" name="rua" onChange={this.onChange} value={state.rua}/>
                      </div>
                      <div className="col-3">
                        <label className="control-label">Número</label>
                        <input className="form-control" type="text" name="numero" onChange={this.onChange} value={state.numero}/>
                      </div>
                    </div>
                    <div className="form-group required">
                      <label>Complemento</label>
                      <input className="form-control" type="text" name="complemento" onChange={this.onChange} value={state.complemento}/>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-6">
                        <label className="control-label">Cidade</label>
                        <input className="form-control" type="text" name="cidade" onChange={this.onChange} value={state.cidade}/>
                      </div>
                      <div className="col-6">
                        <label className="control-label">Bairro</label>
                        <input className="form-control" type="text" name="bairro" onChange={this.onChange} value={state.bairro}/>
                        </div>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-8">
                        <label className="control-label">Estado</label>
                        <select className="custom-select" name="estado" onChange={this.onChange} value={state.estado}>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="EX">Estrangeiro</option>
                        </select>
                      </div>
                      <div className="col-4">
                        <label className="control-label">CEP</label>
                        <input className="form-control" type="text" id="cep" name="cep" onChange={this.onChange} value={state.cep}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <h3>Contato</h3>
                    <div className="form-group required">
                      <label className="control-label">Email</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fas fa-envelope" /></span>
                        </div>
                        <input type="email" id="email" className="form-control" placeholder="Email" name="email" onChange={this.onChange} value={state.email}/>
                        <div className="input-group-prepend">
                          <span id="loading-email" className="input-group-text d-none"><i className="fas fa-spinner fa-spin"></i></span>
                        </div>
                        <button id="button-check" class="btn btn-primary" onClick={() => this.checarEmail(state.email)}>Verificar</button>
                        <div id="erro-servico" className="btn btn-danger d-none">Erro no serviço!</div>
                      </div>
                    </div>

                    <div className="form-group form-row">
                      <div className="col-9">
                        <label>Telefone Fixo</label>
                        <input className="form-control" type="text" id="telefoneResidencial" name="telefoneResidencial" onChange={this.onChange} value={state.telefoneResidencial}/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label className="control-label">Telefone Celular</label>
                        <input className="form-control" type="text" id="telefoneCelular" name="telefoneCelular" onChange={this.onChange} value={state.telefoneCelular}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                <div className="row">
                  <div className="col-md-6">
                    <h3>Assinatura <hr></hr></h3>
                    <div className="form-group required">
                      <label className="control-label" htmlFor="exampleInputFile">Selecione o aquivo</label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input type="file" className="custom-file-input" id="exampleInputFile" onChange={this.transform.bind(this)}/>
                          <label className="custom-file-label" htmlFor="exampleInputFile">{this.state.nomeDoArquivo}</label>
                        </div>
                      </div>
                    </div>
                    <p>Tamanho máximo permitido 550x140 pixels.</p>
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <img src={this.state.imagemAssinatura === ""?semImagem:"data:image/jpeg;base64,"+this.state.imagemAssinatura} width="400px" style={{"border-radius":"10px"}}></img>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div class="card-footer">
                <button id="salvar-botao" class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Salvar</button>
                <a href="/"><button class="btn btn-secondary ml-2">Cancelar</button></a>
              </div>
            </div>
            {/* /.card */}
          </div>
          <div className="modal fade" id="modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{state.modalTitle}</h4>
                    </div>
                    <div className="modal-body">
                        <p>{state.modalText}</p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={this.closeButton} data-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-primary" onClick={this.homeButton} data-dismiss="modal">Retornar a Home</button>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(PractitionerNewForm);