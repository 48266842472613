import React from "react";

const Login = props => {
  window.$('body').addClass("hold-transition login-page")
  return (
    <div>
        {props.children}
    </div>
  );
};

export default Login;
