import React from "react";

export default function Sidebar() {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <div className="brand-link">
          <img
            src={process.env.PUBLIC_URL+"/dist/img/telescribe-logo.png"}
            alt="AdminLTE Logo"
            className="brand-image"
          />
          <span className="brand-text font-weight-light">TeleScribe</span>
        </div>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={process.env.PUBLIC_URL+"/dist/img/avatar5.png"}
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info" style={{color: "white"}}>
                ADMIN
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="nav-icon fas fa-home" />
                  <p>Home</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/Dashboard" className="nav-link">
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>Business Intelligence</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/AuditLog" className="nav-link">
                  <i className="nav-icon fas fa-file-alt" />
                  <p>Trilha de Auditoria</p>
                </a>
              </li>
              
              <li className="nav-header">GERENCIAMENTO</li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-file-medical" />
                  <p>
                    Exames
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/ExamList" className="nav-link px-5">
                      <p>Todos</p>
                    </a>
                  </li>
                  {/*<li className="nav-item">
                    <a href="/Rejeitados" className="nav-link">
                      <p>Rejeitados</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Abonado" className="nav-link">
                      <p>Abonos Pendentes</p>
                    </a>
                  </li>*/}
                </ul>
              </li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-clinic-medical" />
                  <p>
                    Clínicas
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/ClinicList" className="nav-link px-5">
                      <p>Todos</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/ClinicNew" className="nav-link px-5">
                      <p>Novo</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-building" />
                  <p>
                    Central de Laudos
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/CompanyList" className="nav-link px-5">
                      <p>Todos</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/CompanyNew" className="nav-link px-5">
                      <p>Novo</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user-md" />
                  <p>
                    Médico Laudista
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/DoctorList" className="nav-link px-5">
                      <p>Todos</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/DoctorNew" className="nav-link px-5">
                      <p>Novo</p>
                    </a>
                  </li>
                </ul>
              </li>
              {/*<li className="nav-item">
                <a href="/AuditLog" className="nav-link">
                  <i className="nav-icon fas fa-clipboard-list" />
                  <p>Trilha de Auditoria</p>
                </a>
              </li>*/}
              <li className="nav-header">SUPORTE</li>
              <li className="nav-item">
                <div className="nav-link">
                  <i className="nav-icon fas fa-question-circle" />
                  <p>Ajuda</p>
                </div>
              </li>
              <li className="nav-header">Configurações</li>
              <li className="nav-item">
                <a href="/ChangePassword" className="nav-link">
                  <i className="nav-icon fas fa-lock" />
                  <p>Alterar Senha</p>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
