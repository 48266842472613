import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import axiosInstance from '~/helpers/axios'

class WarrantedListing extends Component {
    constructor(props){
        super(props)
        this.state = {
            props : props
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }
    componentDidMount(){
        var $ = window.$
        const {state} = this
        console.log(window.db.rejeitado)
        $(function () {
            $("#jsGrid1").jsGrid({
                width: "100%",
                paging: true,
                pageLoading:true,
                pageSize:10,
                autoload:true,
                controller: {
                    loadData: function(filter) {
                        var d = window.$.Deferred();
                        axiosInstance.get("/exame/abono/?limit=10&page="+filter.pageIndex)
                        .then((response)=>{
                            console.log(response)
                            var da = {
                            data :response.data.exames,
                            itemsCount : response.data.total
                            }
                            d.resolve(da);
                        }).catch((error)=>{
                            console.log(error)
                        })
                        return d.promise();
                    },
                    onError: function() {
                        console.log('Error.');
                    },
                },
                pagerFormat: "Páginas: {first} {prev} {pages} {next} {last}    {pageIndex} de {pageCount}",
                pagePrevText: "Anterior",
                pageNextText: "Próxima",
                pageFirstText: "Primeira",
                pageLastText: "Última",
                pageNavigatorNextText: "...",
                pageNavigatorPrevText: "...",
                noDataContent: "Nenhum dado encontrado",
                loadMessage: "Por favor, aguarde...",
                fields: [
                    { name: "dataAbono", type: "text", title: "Data do abono", width: 1 },
                    { name: "statusAbono", type: "text", title: "Estágio", width: 30 },
                    { name: "motivo", type: "text", title: "Motivo", width: 30 },
                    { name: "medico", type: "text", title: "Médico", width: 19 },
                    { name: "Restante", type: "text", title: "Tempo Restante", width: 19 }
                ]
            });
        })
        
    }
    render() {
        return (
            <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Exames</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                    </div>
                </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Abonos pendentes</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <div id="jsGrid1" />
                </div>
                {/* /.card-body */}
                </div>
                {/* /.card */}
            </section>
            {/* /.content */}
            </div>
        )
    }
}
export default withRouter(WarrantedListing);