import React, { Component, useEffect, useState } from 'react'
import Dados from "./audit.json"
import axiosInstance from '~/helpers/axios'
import { Pagination } from "antd"
import { Spin } from 'antd'


export default function Timeline(){

    
        const [dadosAudit, setDadosAudit] = useState([])
        const [user, setUser] = useState('');
        const [data, setData] = useState('');
        const [type, setType] = useState('');
        const [acao, setAcao] = useState('');
        const [clinic, setClinic] = useState([])
        //Paginação
        const [totalPage, setTotalPage] = useState(300)
        const [itensPerPage, setItensPerPage] = useState(10)
        const [page, setPage] = useState(1)
        const [isLoading, setIsLoading] = useState(true)
        //Fim Paginação

        const handleReset = () => {
            setUser('');
            setType('');
            setAcao('');
            setData('');
            setUser('');
            setPage(1);
        }
        

        useEffect(()=>{
            setIsLoading(true)
            axiosInstance.get(`/audit/pagination?page=${page}&limit=10&acao=${acao}&tabela=${type}`).then((result) =>{
                setDadosAudit(result.data)
                console.log(result.data)
                setTotalPage(result.data?.total)
                setIsLoading(false)
            }).catch((e)=>{
                setIsLoading(true)
            })
        },[user,data,acao,type,page])

        useEffect(()=>{
            axiosInstance.get('/clinic').then((result) =>{
                setClinic(result.data)
            })
        },[])

        useEffect(()=>{
            window.$('.select2').select2().on("select2:select", (e) => {
                setUser(e.params.data.id)
            })
            window.$('.select2').select2({
                data:clinic,
                maximumSelectionLength: 1
            })
        })

        const timelineItens = dadosAudit?.auditDTOList?.map((item)=>{

            return(
                <div key={item.idAuditoria}>
                    <i style={{color:'#fff'}} className={`${item?.acao === 'INSERT' ? 'fas fa-plus bg-green' : item?.acao === 'UPDATE' ? 'fas fa-edit bg-yellow' : item?.acao === 'DELETE' ? 'fas fa-trash bg-red': ""}`}/>
                    <div className="timeline-item">
                        <h3 className="timeline-header"><span className='text-primary'>{item?.email}</span> {item?.acao === 'INSERT' ? 'criou' : item?.acao === 'UPDATE' ? 'atualizou' : item?.acao === 'DELETE' ? 'deletou' : ''} um registro</h3>
                        <div className="timeline-body">
                            <strong>Tipo: </strong> {item?.tabela} <br />
                            <strong>ID: </strong> {item?.idTabelaAlterada} <br />
                            <strong>Status: </strong> {item?.status} <br />
                            <strong>Timestamp: </strong>{item?(new Date(item?.dataAcao?.year,item?.dataAcao?.monthValue - 1,item?.dataAcao?.dayOfMonth,item.dataAcao.hour - 3 >= 0 ? item.dataAcao.hour - 3 : item.dataAcao.hour + 21,item?.dataAcao?.minute, item?.dataAcao?.second )).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short', timeZone: 'America/Sao_Paulo' }) + ' GMT-03:00 (Brasília)':"Data não encontrada"} <br />
                        </div>{/* 
                        <div className="timeline-footer">
                            <a href={`${"/ViewLog/"+item?.idAuditoria}`} className="btn btn-primary btn-sm">Vizualizar detalhes</a>
                        </div> */}
                    </div>
                </div>
            )
        })

        return (
            <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Trilha de Auditoria</h1>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                <i className="fas fa-filter"></i>
                                </span>
                            </div>{/* 
                            <input type='date' className='form-control' value={data} onChange={(e) => setData(e.target.value)}/> */}
                            <select className="form-control" value={type} onChange={(e) => {setType(e.target.value); setPage(1)}}>
                                <option value="" disabled>Tipo</option>
                                <option value="CLINICA">Clinica</option>
                                <option value="MEDICO">Médico</option>
                                <option value="EMPRESA">Central</option>
                                <option value="EXAME">Exame</option>
                            </select>{/* 
                            <div className="select2-indigo">
                                <select className="form-control select2" multiple="multiple" placeholder='Usuário' style={{width: "100%", maxHeight: "40px"}} data-placeholder="Usuário" />
                            </div> */}
                            <select className="form-control" value={acao} onChange={(e) => {setAcao(e.target.value); setPage(1)}}>
                                <option value="" disabled>Ação</option>
                                <option value="DELETE">Deletado</option>
                                <option value="INSERT">Adicionado</option>
                                <option value="UPDATE">Atualizado</option>
                            </select>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={handleReset}>Limpar</button>
                            </div>
                        </div>
                        {/* /.input group */}
                        </div>
                        {/* /.form group */}

                    </div>
                </div>
                
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                {/* Timelime example  */}
                <div className="row">
                    <div className="col-md-12">
                    {/* The time line */}
                    {/* TIRAR O FALSE PARA FUNCIONAR CORRETAMENTE */}
                    {isLoading?
                    <div style={{display: 'flex', justifyContent: 'center', margin: "200px"}}> 
                        <Spin size='large' />
                    </div>:
                    <div className="timeline">
                        <div>
                            <i className="fas fa-clock bg-gray" />
                        </div>
                        {/* timeline time label */}
                        {/* /.timeline-label */}
                        {/* timeline item */}
                        <div style={{display: 'flex', justifyContent: 'center', margin: "30px 0"}}>  
                            <Pagination onChange={(value) => setPage(value)} defaultCurrent={1} total={totalPage} showSizeChanger={false} defaultPageSize={itensPerPage} current={page} />
                        </div>
                        {totalPage === 0?<p className='text-center text-muted'>Sem dados a exibir para este tipo de filtro.</p>:timelineItens}
                        <div style={{display: 'flex', justifyContent: 'center', margin: "30px 0"}}>  
                            <Pagination onChange={(value) => setPage(value)} defaultCurrent={1} total={totalPage} showSizeChanger={false} defaultPageSize={itensPerPage} current={page}  />
                        </div>
                        {/* <div>
                            <i className="fas fa-edit bg-yellow" />
                            <div className="timeline-item">
                                <h3 className="timeline-header"><a href="#">ADMIN</a> editou um registro</h3>
                                <div className="timeline-body">
                                    <strong>Tipo: </strong> Médico <br />
                                    <strong>ID: </strong> 1 <br />
                                    <strong>IP de Origem: </strong> 177.185.46.161 <br />
                                    <strong>Token de Acesso: </strong> N/D <br />
                                    <strong>Timestamp: </strong> 09/03/2021 18:31 <br />
                                </div>
                                <div className="timeline-footer">
                                <a href="/ViewLog/123" className="btn btn-primary btn-sm">Vizualizar detalhes</a>
                                </div>
                            </div>
                        </div> */}
                        {/* END timeline item */}
                        {/* timeline item */}
                        {/* <div>
                        <i className="fas fa-plus bg-green" />
                        <div className="timeline-item">
                            <h3 className="timeline-header"><a href="#">ADMIN</a> enviou um novo registro</h3>
                            <div className="timeline-body">
                                <strong>Tipo: </strong> Médico <br />
                                <strong>IP de Origem: </strong> 177.185.46.161 <br />
                                <strong>Token de Acesso: </strong> N/D <br />
                                <strong>Timestamp: </strong> 09/03/2021 18:27 <br />
                            </div>
                            <div className="timeline-footer">
                            <a className="btn btn-primary btn-sm">Vizualizar detalhes</a>
                            </div>
                        </div>
                        </div> */}
                        {/* END timeline item */}
                        {/* timeline item */}
                        {/* <div>
                        <i className="fas fa-plus bg-green" />
                        <div className="timeline-item">
                            <h3 className="timeline-header"><a href="#">ADMIN</a> enviou um novo registro</h3>
                            <div className="timeline-body">
                                <strong>Tipo: </strong> Médico <br />
                                <strong>IP de Origem: </strong> 177.185.46.161 <br />
                                <strong>Token de Acesso: </strong> N/D <br />
                                <strong>Timestamp: </strong> 09/03/2021 18:25 <br />
                            </div>
                            <div className="timeline-footer">
                            <a className="btn btn-primary btn-sm">Vizualizar detalhes</a>
                            </div>
                        </div>
                        </div> */}
                        {/* END timeline item */}
                        <div>
                            <i className="fas fa-clock bg-gray" />
                        </div>
                    </div>}
                    </div>
                    {/* /.col */}
                </div>
                </div>
                {/* /.timeline */}
            </section>
            {/* /.content */}
            </div>

        )
}
