import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import axiosInstance from '~/helpers/axios';
import Papa from 'papaparse';
import { Spin } from 'antd'

class ExamListing extends Component {

    constructor(props){
        super(props)
        this.state = {
            props : props,
            jsonCsv: [],
            loadingCsv: true,
            dateInit: "",
            dateEnd: "",
            totalABaixar: ""
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }
    componentDidMount(){
        var role = localStorage.getItem("role");
        var $ = window.$
        const {state} = this
        var campo = [
                { name: "idExame", type: "text", title: "ID Exame", width: 1 },
                { name: "idEmpresa", type: "text", title: "ID Central", width: 1 },
                { name: "idClinica", type: "text", title: "ID Clinica", width: 1 },
                { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
                { name: "dataImportacao", type: "text", title: "Data Envio", width: 30 },
                { name: "dataLaudoFormatada", type: "text", title: "Data de Laudo", width: 30, itemTemplate: function(value) {
                    // Verificar se o valor está vazio e atribuir um valor padrão
                    if (!value) {
                        value = "Não Laudado"; // Defina o valor padrão desejado aqui
                    }
                    return value;
                } },
                { name: "sexo", type: "text", title: "Sexo", width: 19 },
                { name: "idade", type: "text", title: "Idade", width: 19 },
                { name: "statusLaudo", type: "text", title: "Status", width: 20 },
        ]

        if(role === "company"){
            campo = [
                { name: "idExame", type: "text", title: "ID Exame", width: 1 },
                { name: "idClinica", type: "text", title: "ID Clinica", width: 1 },
                { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
                { name: "dataImportacao", type: "text", title: "Data Envio", width: 30 },
                { name: "dataLaudoFormatada", type: "text", title: "Data de Laudo", width: 30, itemTemplate: function(value) {
                    // Verificar se o valor está vazio e atribuir um valor padrão
                    if (!value) {
                        value = "Não Laudado"; // Defina o valor padrão desejado aqui
                    }
                    return value;
                } },
                { name: "sexo", type: "text", title: "Sexo", width: 19 },
                { name: "idade", type: "text", title: "Idade", width: 19 },
                { name: "statusLaudo", type: "text", title: "Status", width: 20 },
            ]
        }else if(role === "clinic"){
            campo = [
                { name: "idExame", type: "text", title: "ID Exame", width: 1 },
                { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
                { name: "dataImportacao", type: "text", title: "Data Envio", width: 30 },
                { name: "dataLaudoFormatada", type: "text", title: "Data de Laudo", width: 30, itemTemplate: function(value) {
                    // Verificar se o valor está vazio e atribuir um valor padrão
                    if (!value) {
                        value = "Não Laudado"; // Defina o valor padrão desejado aqui
                    }
                    return value;
                } },
                { name: "sexo", type: "text", title: "Sexo", width: 19 },
                { name: "idade", type: "text", title: "Idade", width: 19 },
                { name: "statusLaudo", type: "text", title: "Status", width: 20 },
            ]
        }else{
            campo = [
                { name: "idExame", type: "text", title: "ID Exame", width: 1 },
                { name: "idEmpresa", type: "text", title: "ID Central", width: 1 },
                { name: "idClinica", type: "text", title: "ID Clinica", width: 1 },
                { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
                { name: "dataImportacao", type: "text", title: "Data Envio", width: 30 },
                { name: "dataLaudoFormatada", type: "text", title: "Data de Laudo", width: 30, itemTemplate: function(value) {
                    // Verificar se o valor está vazio e atribuir um valor padrão
                    if (!value) {
                        value = "Não Laudado"; // Defina o valor padrão desejado aqui
                    }
                    return value;
                } },
                { name: "sexo", type: "text", title: "Sexo", width: 19 },
                { name: "idade", type: "text", title: "Idade", width: 19 },
                { name: "statusLaudo", type: "text", title: "Status", width: 20 },
            ]
        }
        $(function () {
            $("#jsGrid1").jsGrid({
                height: "auto",
                width: "100%",
                paging: true,
                pageLoading:true,
                pageSize:10,
                autoload:true,
                controller: {
                    loadData: function(filter) {
                        var d = window.$.Deferred();
                        axiosInstance.get("/exame/pagination/?limit=10&page="+filter.pageIndex)
                        .then((response)=>{
                            console.log(response)
                            if(response.data.exames === null){
                                var loadPanel = document.querySelector(".jsgrid-load-panel");
                                var noData = document.querySelector("jsgrid-cell");
                                if (loadPanel) {
                                    loadPanel.style.display = "none";
                                    noData.style.display = "flex"
                                }
                            }
                            var da = {
                            data :response.data.exames,
                            itemsCount : response.data.total
                            }
                            d.resolve(da);
                        }).catch((error)=>{
                            console.log(error)
                        })
                        return d.promise();
                    },
                    onError: function() {
                        console.log('Error.');
                    },
                },
                pagerFormat: "Páginas: {first} {prev} {pages} {next} {last}    {pageIndex} de {pageCount}",
                pagePrevText: "Anterior",
                pageNextText: "Próxima",
                pageFirstText: "Primeira",
                pageLastText: "Última",
                pageNavigatorNextText: "...",
                pageNavigatorPrevText: "...",
                noDataContent: "Nenhum dado encontrado",
                loadMessage: "Por favor, aguarde...",
                rowClick: function(args) {
                    state.props.history.push({
                        pathname:'/ExamView',
                        state: args.item
                    })
                },
                fields: campo
            });
        })
        
    }

    downloadCSV = () => {

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

        const fileName = `TSC-EXAMES-${formattedDate}.csv`;

        const dataForCsv = this.state.jsonCsv.map(item => ({
            ID_Clinica: item?.idClinica,
            ID_Central: item?.idEmpresa,
            ID_Exame: item?.idExame,
            Data_laudo: item?.dataLaudoFormatada?item?.dataLaudoFormatada:"Não Laudado",
            Data_envio_Exame: item?.dataInicioColetaFormatada,
            Status_do_laudo: item?.statusLaudo
            
        }));
    
        const csvData = Papa.unparse(dataForCsv);
    
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
    };

    mudarData = async () => {
        console.log("Data início:", this.state.dateInit, "Data final: ", this.state.dateEnd)
        this.setState({loadingCsv: false})
        await axiosInstance.get(`/exame?init=${this.state.dateInit}&end=${this.state.dateEnd}`)
        .then((response)=>{
            this.setState({jsonCsv: response.data, loadingCsv: true})
            console.log(this.state.totalABaixar)
            console.log(response)
        }).catch((error)=>{
            this.setState({ loadingCsv: true })
            console.log(error)
        })

        this.downloadCSV()
    }
    
    render() {
        return (
            <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6 d-flex align-items-center align-content-end">
                        <h1>Exames</h1>
                        {this.state.loadingCsv?<button className='btn btn-primary ml-3' onClick={this.mudarData}>Baixar CSV</button>:<Spin className='ml-3' size='small' />}
                        <div className="col-sm-6">
                            <div className="">
                                <div className="input-group">
                                    {/* 
                                    <input type='date' className='form-control' value={data} onChange={(e) => setData(e.target.value)}/> */}
                                    <div className='d-flex align-items-center'>
                                        <h6 className='m-0 mx-2'>De</h6>
                                        <input
                                            type='date'
                                            id='dateInit'
                                            className='form-control'
                                            value={this.state.dateInit}
                                            onChange={(event) => this.setState({ dateInit: event.target.value })}/>

                                            <h6 className='m-0 mx-2'>Até</h6>

                                        <input
                                            type='date'
                                            id='dateEnd'
                                            disabled = {!this.state.dateInit}
                                            className='form-control'
                                            value={this.state.dateEnd}
                                            onChange={(event) => this.setState({ dateEnd: event.target.value })}/>

                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => {
                                                this.setState({ dateEnd: '', dateInit: '' })}}
                                            >
                                            Limpar
                                            </button>
                                    </div>
                                </div>
                            {/* /.input group */}
                            </div>
                        {/* /.form group */}

                    </div>
                    </div>{/* 
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                    </div> */}
                </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Todos os Exames</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <div id="jsGrid1" />
                </div>
                {/* /.card-body */}
                </div>
                {/* /.card */}
            </section>
            {/* /.content */}
            </div>
        )
    }
}
export default withRouter(ExamListing);