import React, { useState, useContext, useEffect } from 'react'
import useCountdown from '~/components/Countdown/useCountdown'
import axiosInstance from '~/helpers/axios'


const EmailSign = () => {
    const [email, setEmail] = useState("")
    const [emailStatus, setEmailStatus] = useState("")
    const {secondsLeft, start} = useCountdown();

    const data = {
        email: email
    }

    function submit(e){
        e.preventDefault()
        setEmailStatus("loading")
        if(email === ""){
            setEmailStatus("inputs")
        }else{
            start(60)
            axiosInstance.post("/user/forgot-password/", JSON.stringify(data)).then(res => {
                sessionStorage.setItem("email", email)
                setEmailStatus("verify")
                setEmail("")
                console.log(res)
            }).catch(err => {
                console.log(err)
                setEmailStatus("servererror")
                if(err.response.status === 404){
                    setEmailStatus("error")
                }else if(err.response.status === 500){
                    setEmailStatus("servererror")
                }else if(err.response.status === 461){
                    setEmailStatus("try")
                }else if(err.response.status === 429){
                    setEmailStatus("much")
                }
            })
        }
    }
    
    function LoginFailed(props){
        var status = props.status
        if(status=='loading') return <div className="fa-2x d-flex justify-content-center"><i className="fas fa-spinner fa-spin"></i></div>
        if(status=='error') return <div className="alert alert-danger disabled">Usuário inválido!</div>
        if(status=='inputs') return <div className="alert alert-warning disabled">Preencha o campo!</div>
        if(status=='servererror') return <div className="alert alert-warning disabled">Erro no servidor, tente novamente em breve.</div>
        if(status=='try') return <div className="alert alert-warning disabled">Houve várias tentativas de solicitar o código, tente novamente mais tarde.</div>
        if(status=='much') return <div className="alert alert-warning disabled">Muitos acesso simultâneos, aguarde.</div>
        if(status=='verify') return <div className="alert alert-success disabled">Código enviado, verifique seu email!</div>
        return null
    }

    return (
        <div className="login-box">
            <div className="card">
                <div className="card-body login-card-body">
                    <p className="login-box-msg">Digite seu Email</p>
                    <form action="/" method="post">
                        <div className="input-group mb-3">
                            <input type="email" className="form-control" placeholder="Email" name="email" onChange={e => setEmail(e.target.value)} value={email} />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                </div>
                            </div>
                        </div>
                        <LoginFailed status={emailStatus} />
                            {/* /.col */}
                            {secondsLeft > 0?
                                <div className="fa-2x justify-content-center">
                                    <p className="login-box-msg" style={{fontSize: 16}}>Aguarde {secondsLeft} segundos e tente novamente</p>
                                    <div className="col-4">
                                        <button disabled={emailStatus != "verify"} className="btn btn-primary btn-block" style={{width: 300}} onClick={(e) => {e.preventDefault(); window.location.href = "/NewPassword"}}>Inserir Código</button>
                                    </div>
                                </div>:
                                <div className="row justify-content-md-center">
                                    <div className="col-4">
                                        <button className="btn btn-primary btn-block" onClick={submit}>Enviar</button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-light btn-block" onClick={e=> {e.preventDefault(); window.location.href = "/Login"}}>Cancelar</button>
                                    </div>
                                </div>
                            }
                            {/* /.col */}
                    </form>
                </div>
                {/* /.login-card-body */}
            </div>
        </div>
    )
}
export default EmailSign
