import React, { Component } from "react";

export default class CentralDashboard extends Component {
  componentDidMount() {
    var $  = window.$
    $(function () {
      var data        = [],
      totalPoints = 100
      var data2        = []


    function labelFormatter(label, series) {
      return '<div style="font-size:13px; text-align:center; padding:2px; color: #fff; font-weight: 600;">'
        + label
        + '<br>'
        + Math.round(series.percent) + '%</div>'
    }
    })
  }
  render() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Business Intelligence</h1>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                <iframe
                    src={"https://metabase.telescribecardio.com/public/question/2ebc7a35-b43c-481a-a50e-32a25cbddfce?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                    frameborder="0"
                    width="49%"
                    style={{'min-height':'500px', borderRadius: 10}}
                    allowtransparency
                ></iframe>
                <iframe
                    src={"https://metabase.telescribecardio.com/public/question/8f642b1a-7fcf-4557-a838-80f50fb9d43c?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                    frameborder="0"
                    width="49%"
                    style={{'min-height':'500px', borderRadius: 10}}
                    allowtransparency
                ></iframe>
              </div>
              <div className="col-12" style={{display: "flex", flexWrap: "wrap", marginTop: 30, marginBottom: 10, justifyContent: "space-between"}}>
                <iframe
                    src={"https://metabase.telescribecardio.com/public/question/425c6d3c-354a-4080-b245-dd450e14f26d?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                    frameborder="0"
                    width="49%"
                    style={{'min-height':'500px', borderRadius: 10}}
                    allowtransparency
                ></iframe>
                <iframe
                    src={"https://metabase.telescribecardio.com/public/question/e3fe3a4c-6949-4f60-9029-805a6590026f?id="+localStorage.getItem("access")+"#hide_parameters=id"}
                    frameborder="0"
                    width="49%"
                    style={{'min-height':'500px', borderRadius: 10}}
                    allowtransparency
                ></iframe>
              </div>
              <div className="col-12">
                
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
    );
  }
}
