import React from "react";
import Header from "~/components/Header";
import Sidebar from "~/components/Sidebar";
import Footer from "~/components/Footer";
import SidebarDoctor from "~/components/Sidebar_doctor"
import SidebarCompany from "~/components/Sidebar_company"
import SidebarClinic from "~/components/Sidebar_clinic"

function MenuSelect(props){
  const role = localStorage.getItem("role")

  if(role=="hillrom"){ return <Sidebar /> }
  else if(role=="clinic"){ return <SidebarClinic /> }
  else if(role=="doctor"){ return <SidebarDoctor /> }
  else if(role=="company"){ return <SidebarCompany /> }
}

const Dashboard = props => {
  window.$('body').addClass("sidebar-mini layout-fixed layout-navbar-fixed")
  return (
    <div>
      <Header />
      <MenuSelect />
        {props.children}
      <Footer />
    </div>
  );
};

export default Dashboard;
