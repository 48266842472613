import React, { Component } from "react";
import Inputmask from "inputmask"
import axiosInstance from '~/helpers/axios'
import {withRouter} from 'react-router-dom';

class PractitionerProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props:props,
      clinicas: [],
      empresas: [],
      nome:"",
      crm: "",
      dataNascimento: "",
      perfil: "",
      cep: "",
      estado: "AC",
      cidade: "",
      rua: "",
      email: "",
      telefoneCelular: "",
      telefoneResidencial: "",
      bairro: "",
      complemento: "",
      numero: "",
      cpf: "",
      rg: "",
      modalTitle:"Aguardando resposta do servidor...",
      modalText:""
    };
    this.sendButton = this.sendButton.bind(this)
    this.onChange = this.onChange.bind(this)
    this.closeButton = this.closeButton.bind(this)
    this.homeButton = this.homeButton.bind(this)
  }
  componentDidMount(){
    const {state} = this
    var cel = document.getElementById("telefoneCelular")
    var tel = document.getElementById("telefoneResidencial")
    var cep = document.getElementById("cep")
    var celMask = new Inputmask("(99) 99999 9999");
    var telMask = new Inputmask("(99) 9999 9999");
    celMask.mask(cel)
    telMask.mask(tel)
    var cep = document.getElementById("cep")
    var cepMask = new Inputmask("99999-999");
    cepMask.mask(cep)
    axiosInstance.get('/clinic')
    .then((result) =>{
      var clinic = []
      result.data.forEach(element => {
        //console.log(element)
        clinic.push({
          id:element.idClinica,
          text:element.nomeFantasia
        })
      });
      window.$('.select-clinic').select2({
        data:clinic
      })
    })
    axiosInstance.get('/company')
    .then((result) =>{
      var company = []
      result.data.forEach(element => {
        //console.log(element)
        company.push({
          id:element.idEmpresa,
          text:element.nomeFantasia
        })
      });
      window.$('.select-company').select2({
        data:company
      })
    })
    window.$('.select-clinic').select2().on("select2:select", function (e){
      state.clinicas.push(parseInt(e.params.data.id))
      console.log(state.clinicas)
    })
    window.$('.select-clinic').select2().on("select2:unselect", function (e){
      state.clinicas.splice(state.clinicas.indexOf(e.params.data.id),1)
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:select", function (e){
      state.empresas.push(parseInt(e.params.data.id))
      console.log(state.clinicas)
    })
    window.$('.select-company').select2().on("select2:unselect", function (e){
      state.empresas.splice(state.clinicas.indexOf(e.params.data.id),1)
      console.log(state.clinicas)
    })
  }
  onChange(event){
    let { name, value } = event.target;
    this.setState({[name]:value})
  }
  sendButton(){
    const {state} = this
    function dataNasc(data){
      var dataSplit = data.split("/")
      return dataSplit[2]+"-"+dataSplit[1]+"-"+dataSplit[0]+" 00:00"
    }
    var data = {
      clinicas: state.clinicas,
      empresas: state.empresas,
      nome:state.nome,
      crm: state.crm,
      dataNascimento: dataNasc(state.dataNascimento),
      perfil: state.perfil,
      cep: state.cep,
      estado: state.estado,
      cidade: state.cidade,
      rua: state.rua,
      email: state.email,
      telefoneCelular: state.telefoneCelular,
      telefoneResidencial: state.telefoneResidencial,
      bairro: state.bairro,
      complemento: state.complemento,
      numero: state.numero,
      cpf: state.cpf,
      rg: state.rg
    }
    axiosInstance.post('/doctor',data)
    .then((response)=>{
      console.log(response)
      window.$(".modal-header").addClass("bg-success")
      this.setState({modalTitle:"Sucesso!",modalText:"Dados Inseridos com sucesso!",sendError:false})
    }).catch((error)=>{
      console.log(error)
      window.$(".modal-header").addClass("bg-danger")
      this.setState({modalTitle:"Erro!",modalText:"Verifique os dados inseridos e tente novamente!",sendError:true})
    })
  }
  closeButton(){
    if(this.state.sendError){
      window.$(".modal-header").removeClass("bg-danger")
      this.setState({modalTitle:"Aguardando resposta do servidor...",modalText:"",sendError:false})
    }else{
      this.state.props.history.push('/DoctorList')
    }
  }
  homeButton(){
    this.state.props.history.push('/')
  }
  render() {
    const {state} = this
    return (
      <div class="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Editar Perfil</h1>
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section class="content">
          <div className="container-fluid">
            
            <div className="card card-default">
            <div className="card-body">
                <div className="row">
                <div className="col-md-6">
                    <h3>Assinatura Digital</h3>
                    <div>
                      <div className="form-group">
                        <div className="input-group">
                        <p>Insira sua assinatura digital para identificação em laudos realizados</p>
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" id="exampleInputFile" />
                            <label className="custom-file-label" htmlFor="exampleInputFile">Selecionar arquivo...</label>
                          </div>
                        </div>
                      </div>
                      <p>Para inserir sua assinatura digital é necessario concordar com os <a href="#">Termos de Uso</a></p>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1">Concordo com os Termos de Uso e estou ciente das Políticas de Privacidade, previamente estabelecidos</label>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <h3>Senha</h3>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label>Senha atual</label>
                        <input className="form-control" type="password"/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label>Nova senha</label>
                        <input className="form-control" type="password"/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label>Confirmar senha</label>
                        <input className="form-control" type="password"/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div className="card-body">
                <div className="row">
                <div className="col-md-6">
                    <h3>Endereço</h3>
                    <div className="form-group required required form-row">
                      <div className="col-9">
                        <label className="control-label">Logradouro</label>
                        <input className="form-control" type="text" name="rua" onChange={this.onChange} value={state.rua}/>
                      </div>
                      <div className="col-3">
                        <label className="control-label">Número</label>
                        <input className="form-control" type="text" name="numero" onChange={this.onChange} value={state.numero}/>
                      </div>
                    </div>
                    <div className="form-group required">
                      <label>Complemento</label>
                      <input className="form-control" type="text" name="complemento" onChange={this.onChange} value={state.complemento}/>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-6">
                        <label className="control-label">Cidade</label>
                        <input className="form-control" type="text" name="cidade" onChange={this.onChange} value={state.cidade}/>
                      </div>
                      <div className="col-6">
                        <label className="control-label">Bairro</label>
                        <input className="form-control" type="text" name="bairro" onChange={this.onChange} value={state.bairro}/>
                        </div>
                    </div>
                    <div className="form-group required required form-row">
                      <div className="col-8">
                        <label className="control-label">Estado</label>
                        <select className="custom-select" name="estado" onChange={this.onChange} value={state.estado}>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="EX">Estrangeiro</option>
                        </select>
                      </div>
                      <div className="col-4">
                        <label className="control-label">CEP</label>
                        <input className="form-control" type="text" id="cep" name="cep" onChange={this.onChange} value={state.cep}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                  <div className="col-md-6">
                    <h3>Contato</h3>
                    <div className="form-group required">
                      <label className="control-label">Email</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fas fa-envelope" /></span>
                        </div>
                        <input type="email" className="form-control" placeholder="Email" name="email" onChange={this.onChange} value={state.email}/>
                      </div>
                    </div>

                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label>Telefone Fixo</label>
                        <input className="form-control" type="text" id="telefoneResidencial" name="telefoneResidencial" onChange={this.onChange} value={state.telefoneResidencial}/>
                      </div>
                    </div>
                    <div className="form-group required form-row">
                      <div className="col-9">
                        <label className="control-label">Telefone Celular</label>
                        <input className="form-control" type="text" id="telefoneCelular" name="telefoneCelular" onChange={this.onChange} value={state.telefoneCelular}/>
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.card-body */}
              <div class="card-footer">
              <button class="btn btn-primary" data-toggle="modal" data-target="#modal" data-backdrop="static" onClick={this.sendButton}>Salvar</button>
              </div>
            </div>
            {/* /.card */}
          </div>
          <div className="modal fade" id="modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{state.modalTitle}</h4>
                    </div>
                    <div className="modal-body">
                        <p>{state.modalText}</p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-primary" onClick={this.closeButton} data-dismiss="modal">Fechar</button>
                        <button type="button" className="btn btn-primary" onClick={this.homeButton} data-dismiss="modal">Retornar a Home</button>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(PractitionerProfileEdit);