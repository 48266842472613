import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import axiosInstance from '~/helpers/axios';
import Papa from 'papaparse';
import { Spin } from 'antd'

class ExamListingFilter extends Component {

    constructor(props){
        super(props)
        this.state = {
            props : props,
            jsonCsv: [],
            loadingCsv: false,
            filterValue: this.props.match.params.filterValue, // Acesse o filterValue aqui
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }
    
    componentDidMount(){
        const { filterValue } = this.state;
        var $ = window.$
        const {state} = this

        var campo = [
            { name: "idExame", type: "text", title: "ID Exame", width: 1 },
            { name: "idEmpresa", type: "text", title: "ID Central", width: 1 },
            { name: "idClinica", type: "text", title: "ID Clinica", width: 1 },
            { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
            { name: "dataLaudoFormatada", type: "text", title: "Data de Laudo", width: 30, itemTemplate: function(value) {
                // Verificar se o valor está vazio e atribuir um valor padrão
                if (!value) {
                    value = "Não Laudado"; // Defina o valor padrão desejado aqui
                }
                return value;
            } },
            { name: "sexo", type: "text", title: "Sexo", width: 19 },
            { name: "idade", type: "text", title: "Idade", width: 19 },
            { name: "statusLaudo", type: "text", title: "Status", width: 20 },
        ]

        if(filterValue === "REJEITADO"){
            campo = [
                { name: "idExame", type: "text", title: "ID Exame", width: 1 },
                { name: "idEmpresa", type: "text", title: "ID Central", width: 1 },
                { name: "idClinica", type: "text", title: "ID Clinica", width: 1 },
                { name: "dataExameFormatada", type: "text", title: "Data Coleta", width: 30 },
                { name: "sexo", type: "text", title: "Sexo", width: 19 },
                { name: "idade", type: "text", title: "Idade", width: 19 },
                { name: "statusLaudo", type: "text", title: "Status", width: 20 },
            ]
        }
        $(function () {
            $("#jsGrid1").jsGrid({
                height: "auto",
                width: "100%",
                paging: true,
                pageLoading:true,
                pageSize:10,
                autoload:true,
                controller: {
                    loadData: function(filter) {
                        var d = window.$.Deferred();
                        axiosInstance.get("/exame/status?limit=10&page="+filter.pageIndex+"&status="+filterValue)
                        .then((response)=>{
                            console.log(response)
                            if(response.data.exames === null){
                                var loadPanel = document.querySelector(".jsgrid-load-panel");
                                var noData = document.querySelector("jsgrid-cell");
                                if (loadPanel) {
                                    loadPanel.style.display = "none";
                                    noData.style.display = "flex"
                                }
                            }
                            var da = {
                            data :response.data.exames,
                            itemsCount : response.data.total
                            }
                            d.resolve(da);
                        }).catch((error)=>{
                            console.log(error)
                        })
                        return d.promise();
                    },
                    onError: function() {
                        console.log('Error.');
                    },
                },
                pagerFormat: "Páginas: {first} {prev} {pages} {next} {last}    {pageIndex} de {pageCount}",
                pagePrevText: "Anterior",
                pageNextText: "Próxima",
                pageFirstText: "Primeira",
                pageLastText: "Última",
                pageNavigatorNextText: "...",
                pageNavigatorPrevText: "...",
                noDataContent: "Nenhum dado encontrado",
                loadMessage: "Por favor, aguarde...",
                rowClick: function(args) {
                    state.props.history.push({
                        pathname:'/ExamView',
                        state: args.item
                    })
                },
                fields: campo
            });
        })
        
    }

    downloadCSV = () => {

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

        const fileName = `TSC-EXAMES-${formattedDate}.csv`;

        const dataForCsv = this.state.jsonCsv.map(item => ({
            idClinica: item?.idClinica,
            idCentral: item?.idEmpresa,
            idExame: item?.idExame,
            dataLaudo: item?.dataExameFormatada,
            dataEnvioExame: item?.dataDownloadFormatada,
            statusExame: item?.statusLaudo
            
        }));
    
        const csvData = Papa.unparse(dataForCsv);
    
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
    };
    
    render() {
        return (
            <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6 d-flex align-items-center">
                        <h1>Exames {this.state.filterValue === "LAUDADO"?"Laudados":"Rejeitados"}</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    </ol>
                    </div>
                </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Todos os Exames</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <div id="jsGrid1" />
                </div>
                {/* /.card-body */}
                </div>
                {/* /.card */}
            </section>
            {/* /.content */}
            </div>
        )
    }
}
export default withRouter(ExamListingFilter);